export const STATUS = {
    SUCCESS: "SUCCESS",
    NOT_STARTED: "NOT_STARTED",
    FETCHING: "FETCHING",
    FAILED: "FAILED",
}

export const ROLES = {
  SYSADMIN:'SYSADMIN',
  STAFF:'STAFF',
  ADMIN:'ADMIN'
}

export const RoleType = [
  'ADMIN',
  'STAFF',
  'SYSADMIN',
]

export const NOTIFIABLE_TYPES ={
  BROADCAST:'BROADCAST',
  INSTITUTE:'INSTITUTES_BROADCAST',
  ALL_STAFF:'INSTITUTES_STAFF',
  ALL_ALUMNI:'INSTITUTES_ALUMNI',
  GROUPS:'GROUPS_ALUMNI',
  BATCHES:'BATCHES_ALUMNI',
  GHOST:'GHOST',
  USERS:'USER_IDS',

}