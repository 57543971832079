import { Modal, Form, Select, Table, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Truncate } from '../../components/Truncate'
import _ from 'lodash'
import Text from 'antd/lib/typography/Text'

export const SelectBatchModal = ({visible, closeModal, selectedBatches, defaultBatches}) => {

    const [selectedBatchesList, setBatches] = useState([])
    
    const {allBatches} = useSelector(state => ({
        allBatches:state.instituteStaff.singleInstitute.batches
    })) 

    useEffect(() => {
        if(defaultBatches)
            setBatches(defaultBatches)
    }, [defaultBatches])

    const handleSelect = (d) => {
        let data = _.xorBy(selectedBatchesList, [d], 'id')
        setBatches(data)
    }

    const handleSubmit = () => {
        selectedBatches(selectedBatchesList)
        closeModal()
    }

    return(
        <Modal visible={visible} width='60%' title='Batches' okText='Done' onOk={handleSubmit} onCancel={closeModal} >
            <Text>Selected Count: <Text style={{color:'#3498DB', fontWeight:'bold'}}>{selectedBatchesList.length}</Text></Text>
            <br/><br/>
            <Table size='small' bordered pagination={false} dataSource={allBatches || []}>
                <Table.Column title='Batch Name' dataIndex='name'></Table.Column>
                <Table.Column title='Start & End Year'
                    render={d => d.startyear +' - '+ d.endyear}
                ></Table.Column>
                <Table.Column title='Select'
                    render={d => {
                        const selected = _.findIndex(selectedBatchesList,g => g.id === d.id) != -1 
                        return(
                            selected ? 
                            <Button size='small' danger onClick={() => handleSelect(d)}>Remove</Button>
                            :
                            <Button size='small' onClick={() => handleSelect(d)}>Select</Button>
                        )
                    }
                    }
                ></Table.Column>
            </Table>
        </Modal>
    )
}