import { Layout } from "antd";
import { useState } from "react";
import "./Dashboard.css";
import { DashboardHeader } from "../../components/DashboardHeader";
import {AddInstitute} from '../Institute/AddInstitute'
import {ListInstitute} from '../Institute/ListInstitute'

import { SideMenu } from "../../components/SideMenu";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { ManageBatchRoute } from "../ManageBatches";
import { AddAlumni } from "../AddAlumni.js";
import { SearchAlumni } from "../SearchAlumni";
import { AlumniProfile } from "../AlumniProfile.js";
import { ManageGroups } from "../ManageGroups.js";
import { ManageAlumni } from "../ManageAlumni";
import { ManagePosts } from "../ManagePosts";
import { Notifications } from "../Notifications";
import { ManageNotifications } from "../Notifications/ManageNotifications";
import { MainDashboard } from "./MainDashboard";
import { MyProfile } from "../MyProfile";
import { useSelector } from "react-redux";
import { ROLES, RoleType } from "../../Constants";

const { Content, Footer, Sider } = Layout;

export function Dashboard() {
  return (
    <Layout>
      <Sideview />
      <Layout className="site-layout" style={{position:'relative'}}>
        <DashboardHeader />
        <MainContent />
        <Footer style={{ textAlign: "center", position:'absolute', bottom:0, right:0, left:0 }}>
          Alumni Copyright @schoollog 2020
        </Footer>
      </Layout>
    </Layout>
  );
}

const MainContent = props => {
  const {currentUser} = useSelector(state => ({
    currentUser:state.user.user
  }))

  console.log('currentUser', currentUser)
  return (
    <div style={{padding:'20px'}}>
      <Switch>
        {currentUser.role === ROLES.SYSADMIN ? 
          <>
            <Route exact path={ROUTES.INSTITUTE_ADD} component={AddInstitute} />
            {/* <Route exact path="/patient/search" component={FindPatient} /> */}
            <Route exact path={'/'} component={ListInstitute} />
          </>
          :
          <>
            <Route exact path="/" component={MainDashboard} />
            <Route exact path={ROUTES.MANAGE_BATCHES.key} component={ManageBatchRoute} />
            <Route exact path={ROUTES.ADD_ALUMNI} component={AddAlumni} />
            <Route exact path={ROUTES.SEARCH_ALUMNI} component={SearchAlumni} />
            <Route exact path={ROUTES.STUDENT_PROFILE} component={AlumniProfile} />
            <Route exact path={ROUTES.ADD_GROUP.key} component={ManageGroups} />
            <Route exact path={ROUTES.ALUMNIS_LIST.key} component={ManageAlumni} />
            <Route exact path={ROUTES.MANAGE_POSTS} component={ManagePosts} />
            <Route exact path={ROUTES.SEND_NOTIFICATIONS} component={Notifications} />
            <Route exact path={ROUTES.MANAGE_NOTIFICATIONS} component={ManageNotifications} />
            <Route exact path={ROUTES.MY_PROFILE} component={MyProfile} />
          </>
        }
      </Switch>
    </div>
  )
}

const Sideview = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      collapsible
      width={200}
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{
        position: "realative",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          position: "fixed",
          height: "100vh",
          overflow: "auto",
          width: collapsed ? undefined : 200,
          left: 0,
        }}
      >
        <div className="logo" >
          <img style={{width:160}} src="/images/alumni-logo.png" alt=''/>
        </div>

        <SideMenu />
      </div>
    </Sider>
  );
};
