import { Button, Card, Col, Descriptions, Empty, Form, Input, List, Row, Spin, Table, Tabs, Tag, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { AiOutlineAudit } from "react-icons/ai";
import Title from 'antd/lib/typography/Title'
import { useDispatch, useSelector } from 'react-redux'
import { getBatchesRequest } from '../../redux/reducers/batches'
import { STATUS } from '../../Constants'
import { sheetToJSON } from '../../utils/FileHelper';
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { ExportExcel } from '../../components/ExportExcel';
import _ from 'lodash'
import { AddBatchDrawer } from '../../components/AddBatch';
import { useHistory, useParams } from 'react-router-dom';
import { addAlumniExcelAction, getAlumniListAction, resetDuplicateEntries } from '../../redux/reducers/alumni';
import { AlumniTable } from '../ManageAlumni';
import Text from 'antd/lib/typography/Text';

export const ManageBatchRoute = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const [addBatch, setAddBatch] = useState(0)
    const [selectedBatch, selectBatch] = useState()

    const {user, getBatchesStatus, batches, addBatchStatus, updateBatchStatus, alumni} = useSelector((state) => ({
        user:state.user.user,
        getBatchesStatus:state.batches.getBatchesStatus,
        batches:state.batches.batches,
        addBatchStatus:state.batches.addBatchStatus,
        updateBatchStatus:state.batches.updateBatchStatus,
        alumni:state.alumni
    }))

    useEffect(() => {
        dispatch(getBatchesRequest({id:user.staff.institute.id}))
    }, [dispatch, user])

    useEffect(() => {
        if(addBatchStatus === STATUS.SUCCESS)
            setAddBatch(false)
    }, [addBatchStatus])

    useEffect(() => {
        if(params.id) 
            selectBatch(_.find(batches,b => b.id == params.id))
    }, [params.id, updateBatchStatus, getBatchesStatus])


    const onTabChange =(tab) => {
        if(tab == 2)
            dispatch(getAlumniListAction({page:1, limit:10, batchIds:[selectedBatch.id]}))
    }

    const _pageChange = (page) => {
        dispatch(getAlumniListAction({page:page, limit:10, batchIds:[selectedBatch.id]}))
    }

    return(
        <div className="site-page-header-ghost-wrapper">
            <CommonPageHeader
                title="Batches"
                subTitle=""
                extra={[
                    <Button onClick={() => setAddBatch(true)} size='large' icon={<PlusOutlined />}> Add Batch</Button>,
                ]}
            />

            {addBatch ? <AddBatchDrawer selectedBatch={null} closeDrawer={() => setAddBatch(false)} visible={addBatch}/> : null}

            <br/>
            <Card loading={getBatchesStatus == STATUS.FETCHING}>
                {getBatchesStatus == STATUS.SUCCESS ? 
                    <Row gutter={20}>
                        <Col span={6}>
                            <ListBatches batches={batches} selectedBatch={selectedBatch}/>
                            <br/><br/>
                        </Col>
                        <Col span={18} style={{padding:'0 30px'}}>
                            {selectedBatch &&
                                <Tabs defaultActiveKey='1' key={params.id} type='card' onChange={onTabChange}>
                                    <Tabs.TabPane tab='Information' key='1' style={{padding:'0 10px'}}>
                                        <br/>
                                        <BatchDetails updateBatch={() => setAddBatch(true)} batch={selectedBatch}/>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab='Members' key='2' style={{padding:'0 10px'}}>
                                        <br/>
                                            <AlumniTable batchList pageChange={_pageChange}/>
                                        <br/><br/>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab='Add Members' key='3' style={{padding:'0 10px'}}>
                                        <br/>
                                        <AddMembers batch={selectedBatch} institute={user.staff.institute}/>
                                    </Tabs.TabPane>
                                </Tabs>
                            }
                        </Col>
                    </Row>
                    :
                    null
                }
            </Card>
        </div>
    )
}

const AddMembers = ({batch, institute}) => {
    const dispatch = useDispatch()

    let data = [
        {
            name:'', gender:'', dob:'',
            contactPrimary:'', contactSecondary:'',
            emailPrimary:'', emailSecondary:'',
            addressPermanent:'', statePermanent:'', cityPermanent:'', pincodePermanent:'',
            addressLocal:'', stateLocal:'', cityLocal:'', pincodeLocal:'',
        }
    ]

    let defaultKeys = _.keysIn(data[0])
    const requiredFields = ['name', 'contactPrimary', 'emailPrimary']

    const [excelData, onSave] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [dataColumns, setDataColumns] = useState([])
    let [required, setRequired] = useState(0)

    const {alumniExcelStatus, duplicateEntries} = useSelector((state) => ({
        alumniExcelStatus:state.alumni.alumniExcelStatus,
        duplicateEntries:state.alumni.duplicateEntries
    }))

    useEffect(() => {
        return () => dispatch(resetDuplicateEntries())
    }, [dispatch])

    useEffect(() => {
        if(alumniExcelStatus === STATUS.SUCCESS){
            onSave([])
            setDataSource([])
        }
    }, [alumniExcelStatus])

    useEffect(() => {
        if(excelData.length){

            let studentsList = excelData.map(d => ({
                name:d.name, gender:d.gender, dob:d.dob,
                contactPrimary:d.contactPrimary, contactSecondary:d.contactSecondary,
                emailPrimary:d.emailPrimary, emailSecondary:d.emailSecondary,
                addressPermanent:d.addressPermanent, statePermanent:d.statePermanent, cityPermanent:d.cityPermanent, pincodePermanent:d.pincodePermanent,
                addressLocal:d.addressLocal, stateLocal:d.stateLocal, cityLocal:d.cityLocal, pincodeLocal:d.pincodeLocal,
            }))

            let columns = _.keys(studentsList[0]).map(d => ({title:d, dataIndex:d, key:d}))
            
            columns = columns.map((col, i) => 
                _.findIndex(requiredFields,(d, key) => d === col.title) !== -1  ? 
                
                    Object.assign(col, {render:tags => tags || <Tag color='red' key={i}>Required</Tag>, required:true}) 
                    : col
            )

            columns = columns.map(c => c.title === 'dob' ? ({...c, width:130}) 
                :c.title === 'addressPermanent' ? ({...c, width:300})
                    :c.title === 'addressLocal' ? ({...c, width:300})
                        : c
            )

            setDataColumns(columns)

            let requiredCount = 0
            _.forEach(columns, d => d.required ? ++requiredCount : null)
            
            setRequired(requiredCount)
            setDataSource(studentsList.map((d, i) => ({...d, key:++i})))
        }
    }, [excelData])

    const convertFile = (e) => {
        // console.log('e', e)
        sheetToJSON(null, onSave, e.file)
    } 

    const addExcel = () => {
        let studentsData = [...excelData.map(d => Object.assign({}, d))]
        let data = {batchId:batch.id, instituteId:institute.id, 
                data:_.map(studentsData, d => ({user:{
                    addresses:[
                        {address:d.addressPermanent, 
                            state:d.statePermanent, 
                            city:d.cityPermanent, pincode:d.pincodePermanent, type:'permanent'},
                        {address:d.addressLocal, 
                            state:d.stateLocal, 
                            city:d.cityLocal, pincode:d.pincodeLocal, type:'correspondence'},
                    ],
                    contact:d.contactPrimary,
                    email:d.emailPrimary,
                    isVerified:true,
                    ..._.omit(d, ['addressPermanent', 'contactPrimary', 'emailPrimary', 'statePermanent', 'cityPermanent', 'pincodePermanent', 'addressLocal', 'stateLocal', 'cityLocal', 'pincodeLocal'])
                }}))
            }

        dispatch(addAlumniExcelAction(data))
    }

    const instructions = [
        ' Date of birth sould be in format YYYY-MM-DD eg. 2020-08-02.',
        ' Email and contact should be unique, should not be added earlier.',
        ' Gender Male/Female',
        ' Required fields name, primary contact and primary email.'
    ]
    
    return(
        <>
            <Form>
                <Form.Item label='Download Excel'>
                    <ExportExcel data={data}/>
                </Form.Item>
                <Form.Item label='Upload Excel'>
                    <Upload beforeUpload={() => false} showUploadList={false} accept=".xlsx, .xls, .csv" onChange={convertFile}>
                        <Button icon={<UploadOutlined />}>Upload Excel</Button>
                    </Upload>
                    {/* <Input type='file' onChange={convertFile} accept=".xlsx, .xls, .csv"/> */}
                </Form.Item>
                <div>
                    <List
                        style={{background:'#E3F2FD'}}
                        header={<div style={{fontSize:'16px', fontWeight:'bold', padding:'0 10px'}}>Instructions</div>}
                        dataSource={instructions}
                        renderItem={item => <List.Item style={{padding:'0px 10px 4px'}}>- {item}</List.Item>}
                    />
                    <br/><br/>
                </div>

            </Form>

            {dataSource.length ? 
                <Table scroll={{x:1000}}
                    pagination={{position:['bottomCenter']}}
                    bordered
                    dataSource={dataSource}
                    columns={dataColumns}
                />
                : null
            }

            {duplicateEntries ? 
                <div style={{color:'red'}}>
                    * Duplicate Entries<br/>
                    {duplicateEntries.contacts?.length ? 
                        <Text style={{color:'red', display:'block'}}>Primary Contacts: {_.join(duplicateEntries.contacts, ',')}</Text>
                        : null 
                    }
                    {duplicateEntries.contactsSecondary?.length ? 
                        <Text style={{color:'red', display:'block'}}>Secondary Contacts: {_.join(duplicateEntries.contactsSecondary, ',')}</Text>
                        : null 
                    } 
                </div>
                :
                null
            }
            {required ? 
                <div style={{color:'red'}}>
                    * need required fields to add student
                </div>
                : null
            }

            {dataSource.length ? 
                <Button size='large' disabled={required} block disabled={!excelData.length} loading={alumniExcelStatus === STATUS.FETCHING} onClick={addExcel}>
                    Add Alumni
                </Button>
                :
                null
            }
            <br/><br/><br/>
        </>
    )
}

const BatchDetails = ({batch}) => {
    const [updateBatch, setUpdateBatch] = useState()

    const updateBatchStatus = useSelector(state => state.batches.updateBatchStatus)

    useEffect(() => {
        if(updateBatchStatus === STATUS.SUCCESS)
            setUpdateBatch(false)
    }, [updateBatchStatus])
    return batch ? (
        <div>
            <Descriptions title={<Title level={4}>Batch Information</Title>} bordered 
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                extra={
                    <Button 
                        icon={<EditOutlined />}
                        onClick={() => setUpdateBatch(true)}
                    >
                        Update
                    </Button>
                }
            >
                <Descriptions.Item label="Name" span={2}>{batch.name}</Descriptions.Item>
                <Descriptions.Item label="Start Year" onClick={updateBatch}>{batch.startyear || '-'}</Descriptions.Item>
                <Descriptions.Item label="End Year">{batch.endyear || '-'}</Descriptions.Item>
            </Descriptions>

            {updateBatch ? <AddBatchDrawer selectedBatch={batch} closeDrawer={() => setUpdateBatch(false)} visible={updateBatch}/> : null }
            <br/><br/>
        </div>
    ) : <div></div>
}

const ListBatches = ({batches, selectedBatch}) => {
    const data = batches
    const history = useHistory()

    return(
        <div style={{maxHeight:'500px', overflow:'auto'}}>
            <List
                bordered
                header={<Title level={4}>Batches</Title>}
                itemLayout="horizontal"
                dataSource={_.orderBy(data, ['createdAt'], ['desc'])}
                renderItem={item => {
                    let selected = selectedBatch && selectedBatch.id == item.id  
                    return(
                        <List.Item style={{cursor:'pointer'}} className={selected ? 'listItemBg' : ''} onClick={() => history.push('/manage-batches/'+item.id)}>
                            <List.Item.Meta
                                avatar={<AiOutlineAudit fontSize='35'/>}
                                title={<div className={selected ? 'listItemBg' : ''} style={{fontSize:'15px'}}>{item.name}</div>}
                                description={<div className={selected ? 'listItemBg' : ''}>{`${item.startyear || '-'} - ${item.endyear || '-'}`}</div>}
                            />
                        </List.Item>
                    )}
                }
            />
        </div>
    )
}