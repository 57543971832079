import _ from 'lodash';
import { STATUS } from "../../../Constants";
import { ErrorMessage, SuccessMessage } from "../../../Constants/CommonAlerts";
import { apis } from "../../../services/api/apis";
const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit");

export const addAlumniAction = createAsyncThunk(
    'create/member',
    async(payload, thunkAPI) => {
        const response = await apis.addAlumniApi(payload)
        const {ok, problem, data} = response
        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(data)
    }
)

export const UpdateUserAction = createAsyncThunk(
    'user/update',
    async(payload, thunkAPI) => {
        const response = await apis.updateUserApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
        return thunkAPI.rejectWithValue(problem)

    }
)

export const UpdateAddressAction = createAsyncThunk(
    'user/address',
    async(payload, thunkAPI) => {
        const response = await apis.updateAddressApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
        return thunkAPI.rejectWithValue(problem)

    }
)

export const getAlumniAction = createAsyncThunk(
    'member/get',
    async(payload, thunkAPI) => {
        const response = await apis.getAlumniApi(payload)
        const {ok, problem, data} = response
        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem) 
    }
)

export const searchAlumnisAction = createAsyncThunk(
    'member/search',
    async(payload, thunkAPI) => {
        const response = await apis.searchAlumnis(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const addEducationAction = createAsyncThunk(
    'member/addEducation',
    async(payload, thunkAPI) => {
        const response = await apis.addEducationApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const assignGroupsAction = createAsyncThunk(
    'member/groups',
    async(payload, thunkAPI) => {
        const response = await apis.assignGroupsApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const editEducationAction = createAsyncThunk(
    'member/editEducation',
    async(payload, thunkAPI) => {
        const response = await apis.editEducationApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const removeGroupAction = createAsyncThunk(
    'group/remove',
    async(payload, thunkAPI) => {
        const response = await apis.removeGroupApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return {extraData:payload}
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const updateMemberAction = createAsyncThunk(
    'member/update',
    async(payload, thunkAPI) => {
        const response = await apis.updateMemberApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const addAlumniExcelAction = createAsyncThunk(
    'member/batch-member',
    async(payload, thunkAPI) => {
        const response = await apis.addAlumniExcelApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(data)
    }
)

export const deleteAlumniEducationAction = createAsyncThunk(
    'member/deleteEducation', 
    async(payload, thunkAPI) => {
        const response = await apis.deleteAlumniEducationApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return ({data, extraData:payload})
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)


export const addExperienceAction = createAsyncThunk(
    'member/addExperience',
    async(payload, thunkAPI) => {
        const response = await apis.addExperienceApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const editExperienceAction = createAsyncThunk(
    'member/editExperience',
    async(payload, thunkAPI) => {
        const response = await apis.editExperienceApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else
            return thunkAPI.rejectWithValue(problem)
    }
)

export const deleteAlumniExperienceAction = createAsyncThunk(
    'member/deleteExperience', 
    async(payload, thunkAPI) => {
        const response = await apis.deleteAlumniExperienceApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return ({data, extraData:payload})
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const getAlumniListAction = createAsyncThunk(
    'member/list/search', 
    async(payload, thunkAPI) => {
        const response = await apis.getalumniListApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return ({data, extraData:payload})
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const getGroupAlumniAction = createAsyncThunk(
    'group/members/get', 
    async(payload, thunkAPI) => {
        const response = await apis.getalumniListApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const memberVerifyAction = createAsyncThunk(
    'member/verify', 
    async(payload, thunkAPI) => {
        const response = await apis.memberVerifyApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const addAchievementAction = createAsyncThunk(
    'achievement/add', 
    async(payload, thunkAPI) => {
        const response = await apis.addAchievementApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const updateAchievementAction = createAsyncThunk(
    'achievement/update', 
    async(payload, thunkAPI) => {
        const response = await apis.updateAchievementApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const deleteAchievementAction = createAsyncThunk(
    'achievement/delete', 
    async(payload, thunkAPI) => {
        const response = await apis.deleteAchievementApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return {data, extraData:payload}
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)


export const updateAlumniBatchAction = createAsyncThunk(
    'alumni-batch/update', 
    async(payload, thunkAPI) => {
        const response = await apis.updateAlumnusBatchApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

export const deleteAlumnusAction = createAsyncThunk(
    'alumnus/delete', 
    async(payload, thunkAPI) => {
        const response = await apis.deleteAlumnusApi(payload)
        const {ok, problem, data} = response

        if(ok)
            return data
        else 
            return thunkAPI.rejectWithValue(problem)
    }
)

const initialState = {
    addEducationStatus: STATUS.NOT_STARTED,
    addExperienceStatus: STATUS.NOT_STARTED,
    deleteAlumniEducationStatus: STATUS.NOT_STARTED,
    deleteAlumniExperienceStatus: STATUS.NOT_STARTED
}

const alumniSlice = createSlice({
    name:'alumni',
    initialState,
    reducers: {
        resetEducationStatusAction: (state, action) => {
          state.addEducationStatus = STATUS.NOT_STARTED
          state.deleteAlumniEducationStatus = STATUS.NOT_STARTED
        },
        resetExperienceStatusAction: (state, action) => {
          state.addExperienceStatus = STATUS.NOT_STARTED
          state.deleteAlumniExperienceStatus = STATUS.NOT_STARTED
        },

        resetEditAlumni:state => {
            state.editEducationStatus = STATUS.NOT_STARTED
        },

        resetDuplicateEntries:state => {
            state.duplicateEntries = null
        }
    },
    extraReducers:{
        [deleteAlumnusAction.pending] : (state) => {
            state.deleteAlumnusStatus = STATUS.FETCHING
        },
        [deleteAlumnusAction.fulfilled]:(state, action)=>{
            state.deleteAlumnusStatus = STATUS.SUCCESS
            SuccessMessage()
        },
        [deleteAlumnusAction.rejected]:(state)=>{
            state.deleteAlumnusStatus = STATUS.FAILED
        },
        
        [updateAlumniBatchAction.pending] : (state) => {
            state.updateBatchStatus = STATUS.FETCHING
        },
        [updateAlumniBatchAction.fulfilled]:(state, action)=>{
            let indx = _.findIndex(state.alumniList.data,d => d.id === action.payload.id)
            if(indx !== -1){
                state.alumniList.data[indx].batch = action.payload.batch
            }

            state.updateBatchStatus = STATUS.SUCCESS
            SuccessMessage()
        },
        [updateAlumniBatchAction.rejected]:(state)=>{
            state.updateBatchStatus = STATUS.FAILED
        },
        
        [deleteAchievementAction.pending] : (state) => {
            state.deleteAchievementStatus = STATUS.FETCHING
        },
        [deleteAchievementAction.fulfilled]:(state, action)=>{
            _.remove(state.currentAlumni.achievements, g => g.id === action.payload.extraData.id)

            state.deleteAchievementStatus = STATUS.SUCCESS
            SuccessMessage()
        },
        [deleteAchievementAction.rejected]:(state)=>{
            state.deleteAchievementStatus = STATUS.FAILED
        },

        [updateAchievementAction.pending] : (state) => {
            state.updateAchievementStatus = STATUS.FETCHING
        },
        [updateAchievementAction.fulfilled]:(state, action)=>{
            let findEducation = state.currentAlumni.achievements?.length ? _.findIndex(state.currentAlumni.achievements, s => s.id === action.payload.id) : -1
            if(findEducation !== -1){
                state.currentAlumni.achievements[findEducation] = action.payload 
            }

            state.updateAchievementStatus = STATUS.SUCCESS
            SuccessMessage()
        },
        [updateAchievementAction.rejected]:(state)=>{
            state.updateAchievementStatus = STATUS.FAILED
        },

        [addAchievementAction.pending] : (state) => {
            state.addAchievementStatus = STATUS.FETCHING
        },
        [addAchievementAction.fulfilled]:(state, action)=>{
            state.currentAlumni = Object.assign({}, state.currentAlumni, 
                {achievements: state.currentAlumni.achievements?.length ? 
                    _.concat(state.currentAlumni.achievements, action.payload) 
                    : [action.payload]}
            )

            state.addAchievementStatus = STATUS.SUCCESS
            SuccessMessage()
        },
        [addAchievementAction.rejected]:(state)=>{
            state.addAchievementStatus = STATUS.FAILED
        },

        [memberVerifyAction.pending] : (state) => {
            state.memberVerifyStatus = STATUS.FETCHING
        },
        [memberVerifyAction.fulfilled]:(state, action)=>{
            state.memberVerifyStatus = STATUS.SUCCESS
    
            let indx = _.findIndex(state.alumniList.data,a => a.id === action.payload.id)
            if(indx !== -1)
                state.alumniList.data[indx].isVerified = action.payload.isVerified
            
                SuccessMessage("success")
        },
        [memberVerifyAction.rejected]:(state, action)=>{
            state.memberVerifyStatus = STATUS.FAILED
            ErrorMessage(action.error.message)
        },
        
        [addAlumniAction.pending] : (state) => {
            state.addAlumniStatus = STATUS.FETCHING
        },
        [addAlumniAction.fulfilled]:(state, action)=>{
            state.addAlumniStatus = STATUS.SUCCESS
            SuccessMessage("Alumni Added")
        },
        [addAlumniAction.rejected]:(state, action)=>{
            state.addAlumniStatus = STATUS.FAILED
            ErrorMessage(action.payload?.message)
        },

        [getAlumniAction.pending] : (state) => {
            state.getAlumniStatus = STATUS.FETCHING
        },
        [getAlumniAction.fulfilled]:(state, action)=>{
            state.getAlumniStatus = STATUS.SUCCESS
            state.currentAlumni = action.payload
        },
        [getAlumniAction.rejected]:(state, action)=>{
            state.getAlumniStatus = STATUS.FAILED
        },

        [searchAlumnisAction.pending] : (state) => {
            state.searchAlmnisStatus = STATUS.FETCHING
        },
        [searchAlumnisAction.fulfilled]:(state, action)=>{
            state.searchAlmnisStatus = STATUS.SUCCESS
            state.alumnisList = action.payload.data
        },
        [searchAlumnisAction.rejected]:(state, action)=>{
            state.searchAlmnisStatus = STATUS.FAILED
            ErrorMessage(action.error.message)
        },

        [addEducationAction.pending] : (state) => {
            state.addEducationStatus = STATUS.FETCHING
        },

        [addEducationAction.fulfilled]:(state, action)=>{
            SuccessMessage()
            state.addEducationStatus = STATUS.SUCCESS
            state.currentAlumni = Object.assign({}, state.currentAlumni, {educations: state.currentAlumni.educations ? _.concat(state.currentAlumni.educations, action.payload) : [action.payload]})
        },

        [addEducationAction.rejected]:(state, action)=>{
            state.addEducationStatus = STATUS.FAILED
            ErrorMessage(action.error.message)
        },

        [editEducationAction.pending] : (state) => {
            state.editEducationStatus = STATUS.FETCHING
        },

        [editEducationAction.fulfilled]:(state, action)=>{
            let findEducation = state.currentAlumni.educations ? _.findIndex(state.currentAlumni.educations, s => s.id == action.payload.id) : -1
            if(findEducation != -1){
                state.currentAlumni.educations[findEducation] = action.payload 
            }

            state.editEducationStatus = STATUS.SUCCESS
            SuccessMessage()
        //    state.currentAlumni = state.currentAlumni
        },

        [editEducationAction.rejected]:(state, action)=>{
            state.addEducationStatus = STATUS.FAILED
            ErrorMessage(action.error.message)
        },

        [deleteAlumniEducationAction.pending] : (state) => {
            state.deleteAlumniEducationStatus = STATUS.FETCHING
        },
        [deleteAlumniEducationAction.fulfilled] : (state, action) => {
            SuccessMessage('Profile Updated')
            state.currentAlumni.educations = _.filter(state.currentAlumni.educations, g => g.id != action.payload.extraData.id)
            state.deleteAlumniEducationStatus = STATUS.SUCCESS
        },
        [deleteAlumniEducationAction.rejected]: (state) => {
            state.deleteAlumniEducationStatus = STATUS.FAILED
        },

        [assignGroupsAction.pending] : (state) => {
            state.assignGroupsStatus = STATUS.FETCHING
        },
        [assignGroupsAction.fulfilled]:(state, action)=>{
            SuccessMessage('Groups assigned to student')
            state.assignGroupsStatus = STATUS.SUCCESS

            if(state.currentAlumni){
                let groups = _.unionBy(action.payload, state.currentAlumni.groups, 'id')
                state.currentAlumni.groups = groups
            }
        },
        [assignGroupsAction.rejected]:(state, action)=>{
            state.assignGroupsStatus = STATUS.FAILED
        },

        [UpdateUserAction.pending] : (state) => {
            state.updateUserStatus = STATUS.FETCHING
        },
        [UpdateUserAction.fulfilled] : (state, action) => {
            SuccessMessage('Profile Updated')
            state.updateUserStatus = STATUS.SUCCESS
            
            if(state.currentAlumni)
                state.currentAlumni.user = Object.assign(state.currentAlumni.user, {...action.payload})
        },
        [UpdateUserAction.rejected]: (state) => {
            ErrorMessage()
            state.updateUserStatus = STATUS.FAILED
        },

        [removeGroupAction.pending] : (state) => {
            state.removeGroupStatus = STATUS.FETCHING
        },
        [removeGroupAction.fulfilled] : (state, action) => {
            SuccessMessage('Group Removed')
            state.removeGroupStatus = STATUS.SUCCESS    
            
            if(state.currentAlumni)
                _.remove(state.currentAlumni.groups,g => g.groupId === action.payload.extraData.groupId)

            if(state.groupMembers?.data.length && action.payload.extraData.memberIds)
                _.remove(state.groupMembers.data,d => d.id === action.payload.extraData.memberIds[0])
        },
        [removeGroupAction.rejected]: (state) => {
            state.removeGroupStatus = STATUS.FAILED
        },

        [addAlumniExcelAction.pending] : (state) => {
            state.alumniExcelStatus = STATUS.FETCHING
        },
        [addAlumniExcelAction.fulfilled] : (state, action) => {
            if(action.payload.error)
                ErrorMessage(action.response.msg)
            else
                SuccessMessage('Alumni Added')
                
            state.alumniExcelStatus = STATUS.SUCCESS
            state.duplicateEntries = null
        },
        [addAlumniExcelAction.rejected]: (state, action) => {
            console.log('action', action)
            if(action.payload?.response){
                ErrorMessage(action.payload.response?.msg)
                state.duplicateEntries = action.payload.response
            }else{
                ErrorMessage(action.payload?.message, 6)
            }
            state.alumniExcelStatus = STATUS.FAILED
        },

        [updateMemberAction.pending] : (state) => {
            state.updateMemberStatus = STATUS.FETCHING
        },
        [updateMemberAction.fulfilled] : (state, action) => {
            SuccessMessage()
            state.updateMemberStatus = STATUS.SUCCESS
            state.currentAlumni.batch = action.payload.batch
        },
        [updateMemberAction.rejected]: (state) => {
            ErrorMessage()
            state.updateMemberStatus = STATUS.FAILED
        },

        [addExperienceAction.pending] : (state) => {
            state.addExperienceStatus = STATUS.FETCHING
        },

        [addExperienceAction.fulfilled]:(state, action)=>{
            SuccessMessage()
            state.addExperienceStatus = STATUS.SUCCESS
            state.currentAlumni = Object.assign({}, state.currentAlumni, {experiences: state.currentAlumni.experiences ? _.concat(state.currentAlumni.experiences, action.payload) : [action.payload]})
        },

        [addExperienceAction.rejected]:(state, action)=>{
            state.addExperienceStatus = STATUS.FAILED
            ErrorMessage(action.error.message)
        },

        [editExperienceAction.pending] : (state) => {
            state.editExperienceStatus = STATUS.FETCHING
        },

        [editExperienceAction.fulfilled]:(state, action)=>{
            let findExperience = state.currentAlumni.experiences ? _.findIndex(state.currentAlumni.experiences, s => s.id == action.payload.id) : -1
            if(findExperience != -1){
                state.currentAlumni.experiences[findExperience] = action.payload 
            }

            state.editExperienceStatus = STATUS.SUCCESS
            SuccessMessage()
        //    state.currentAlumni = state.currentAlumni
        },
        [editExperienceAction.rejected]:(state, action)=>{
            state.editExperienceStatus = STATUS.FAILED
            ErrorMessage(action.error.message)
        },

        [deleteAlumniExperienceAction.pending] : (state) => {
            state.deleteAlumniExperienceStatus = STATUS.FETCHING
        },
        [deleteAlumniExperienceAction.fulfilled] : (state, action) => {
            SuccessMessage()
            state.currentAlumni.experiences = _.filter(state.currentAlumni.experiences, g => g.id != action.payload.extraData.id)
            state.deleteAlumniExperienceStatus = STATUS.SUCCESS
        },
        [deleteAlumniExperienceAction.rejected]: (state) => {
            state.deleteAlumniExperienceStatus = STATUS.FAILED
        },

        [getAlumniListAction.pending] : (state) => {
            state.getAlumniListStatus = STATUS.FETCHING
        },
        [getAlumniListAction.fulfilled] : (state, action) => {
            state.getAlumniListStatus = STATUS.SUCCESS
            state.alumniList = action.payload.data
        },
        [getAlumniListAction.rejected]: (state) => {
            state.getAlumniListStatus = STATUS.FAILED
        },

        [getGroupAlumniAction.pending] : (state) => {
            state.getGroupAlumniStatus = STATUS.FETCHING
        },
        [getGroupAlumniAction.fulfilled] : (state, action) => {
            state.getGroupAlumniStatus = STATUS.SUCCESS
            state.groupMembers = action.payload
        },
        [getGroupAlumniAction.rejected]: (state) => {
            state.getGroupAlumniStatus = STATUS.FAILED
        },

        [UpdateAddressAction.pending] : (state) => {
            state.updateAddressStatus = STATUS.FETCHING
        },
        [UpdateAddressAction.fulfilled] : (state, action) => {
            SuccessMessage('Address Updated')
            state.updateAddressStatus = STATUS.SUCCESS
            state.currentAlumni.user.addresses = action.payload.addresses
        },
        [UpdateAddressAction.rejected]: (state) => {
            ErrorMessage()
            state.updateAddressStatus = STATUS.FAILED
        },
    }
})

export const { resetEducationStatusAction, resetExperienceStatusAction, resetEditAlumni, resetDuplicateEntries } = alumniSlice.actions
export const alumniReducer = alumniSlice.reducer