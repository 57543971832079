import { Button, Modal, Space, Table, Tag, Select, Input, Form } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAlumniListAction } from '../../redux/reducers/alumni'
import _ from 'lodash'
import moment from 'moment'
import Text from 'antd/lib/typography/Text'
import { STATUS } from '../../Constants'
import { countChanges } from 'jest-diff/build/printDiffs'

export const AlumniModal = ({visible, closeModal, selectedAlumnis, defaultAlumnis}) => {
    const dispatch = useDispatch()
    const defaultFilter = {searchKeys:['isVerified'], searchVals:[true]}
    const [form] = Form.useForm()

    const {alumniList, getAlumniListStatus, institute} = useSelector(state => ({
        alumniList:state.alumni.alumniList || {},
        getAlumniListStatus:state.alumni.getAlumniListStatus,
        institute:state.instituteStaff.singleInstitute
    }))

    const [selectedAlumnisList, setAlumnis] = useState([])

    useEffect(() => {
        if(defaultAlumnis)
            setAlumnis(defaultAlumnis)
    }, [defaultAlumnis])

    useEffect(() => {
        dispatch(getAlumniListAction(defaultFilter))
    }, [dispatch])

    const handleSelectAlumni = (alumnus) => {
        let data = _.xorBy(selectedAlumnisList, [alumnus], 'id')
        setAlumnis(data)
    }

    const handleSubmit = () => {
        selectedAlumnis(selectedAlumnisList)
        closeModal()
    }

    const changePage = (e) => {
        dispatch(getAlumniListAction({page:e.current, ...defaultFilter}))
    }

    const handleFormSubmit = (form) => {
        let formData = form
        formData = _.omit(formData, 'batchIds')
        formData = _.omitBy(formData,d => !d)
        
        let searchKeys = ['isVerified', ..._.compact(_.keys(formData))]
        let searchVals = [true, ..._.compact(_.map(formData,d => d))]
        let batchIds = form.batchIds
        
        dispatch(getAlumniListAction({page:1, searchKeys:[...searchKeys], searchVals:[...searchVals], batchIds}))
    }

    const handleReset = () => {
        dispatch(getAlumniListAction(defaultFilter))
        form.resetFields()

    }

    let dataSource = useMemo(() => alumniList?.data?.length ? _.filter(alumniList.data,d => d.isVerified) : [], [alumniList]) 
    return(
        <Modal title='Select Alumni' width='80%' visible={visible} onCancel={closeModal} 
            onOk={handleSubmit} okText='Done' 
        >
            <Text>Selected Count: <Text style={{color:'#3498DB', fontWeight:'bold'}}>{selectedAlumnisList.length}</Text></Text>
            <br/><br/>

            <Form form={form} onFinish={handleFormSubmit}>
                <Space>
                    <Form.Item name='batchIds'>
                        <Select mode="multiple" style={{minWidth:200}} size='small' placeholder='Select Batch'>
                            {institute.batches?.length ? institute.batches.map(btc => 
                                <Select.Option value={btc.id} key={btc.id}>{btc.name}</Select.Option>
                            ) : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name='gender'>
                        <Select size='small' placeholder='Select Gender'>
                            <Select.Option value='MALE'>Male</Select.Option>
                            <Select.Option value='FEMALE'>Female</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='contact'>
                        <Input size='small' type='number' placeholder='Contact' />
                    </Form.Item>
                    <Form.Item name='name'>
                        <Input size='small' placeholder='Name' />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button htmlType='submit' size='small'>Apply</Button>
                            <Button onClick={handleReset} size='small'>Reset</Button>
                        </Space>
                    </Form.Item>
                </Space>
            </Form>
            
            <Table size='small' loading={getAlumniListStatus === STATUS.FETCHING} dataSource={dataSource || []} bordered
                pagination={{showSizeChanger:false, current:parseInt(alumniList?.page) || 1, 
                        pageSize:alumniList.limit, 
                        total:alumniList.totalCount, 
                        position:['bottomCenter', 'topCenter']
                    }}
                onChange={changePage}
                rowClassName={d => {
                    const selected = _.findIndex(selectedAlumnisList,a => a.id === d.id) !== -1
                    return selected ? 'selectedRow' : ''
                }}
            >
                <Table.Column title='Name' dataIndex='user'
                    render={d => d.name}
                ></Table.Column>
                <Table.Column title='Batch' dataIndex='batch'
                    render={d => d ? <Tag>{d?.name}</Tag> : null}
                ></Table.Column>
                <Table.Column title='Groups' dataIndex='groups'
                    render={d => _.join(d.map(g => g.group?.name), ', ')}
                ></Table.Column>
                <Table.Column title='Contact' dataIndex='user'
                    render={d => d.contact}
                ></Table.Column>
                <Table.Column title='Date of Birth' dataIndex='user'
                    render={d => d.dob ? moment(d.dob).format('ll') : null}
                ></Table.Column>
                <Table.Column title='Gender' dataIndex='user'
                    render={d => _.capitalize(d.gender)}
                ></Table.Column>
                <Table.Column title='Actions'
                    render={d => {
                        const selected = _.findIndex(selectedAlumnisList,a => a.id === d.id) !== -1
                        return(
                            selected ? 
                            <Button size='small' disabled={_.find(selectedAlumnisList,a => a.id === d.id)?.alreadyAdded} danger onClick={() => handleSelectAlumni(d)}>Remove</Button>
                            :
                            <Button size='small' onClick={() => handleSelectAlumni(d)}>Select</Button>
                        )
                    }
                }
                ></Table.Column>
            </Table>
        </Modal>
    )
}