import { Button, Card, Checkbox, DatePicker, Drawer, Form, Input, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../Constants'
import { getBatchesRequest } from '../redux/reducers/batches'
import { getStatesAction } from '../redux/reducers/states'
import _ from 'lodash'
import { UpdateAddressAction } from '../redux/reducers/alumni'
import Text from 'antd/lib/typography/Text'

export const UpdateAddress = ({currentAlumni, visible, closeDrawer}) => {
    const dispatch = useDispatch()
    const [from] = Form.useForm()
    const {states, batches, user, alumni} = useSelector((state) => ({
        states:state.states,
        batches:state.batches,
        user:state.user.user,
        alumni:state.alumni,
    }))
    
    useEffect(() => {
        if(states.getStatesStatus != STATUS.SUCCESS)
            dispatch(getStatesAction())

        if(states.getBatchesStatus != STATUS.SUCCESS)
            dispatch(getBatchesRequest({id:user.staff.institute.id}))
    }, [])

    const [selectedState, setState] = useState('')
    const [selectedCity, setCity] = useState('')
    const [formKey, setFormKey] = useState(1)

    const [perAddress, setPerAddress] = useState({type:'permanent'})
    const [locAddress, setLocAddress] = useState({type:'correspondence'})
    const [perAsLocAddress, setPerAsLocAddress] = useState()

    useEffect(() => {
        if(states.getStatesStatus == STATUS.SUCCESS){
            let state= _.find(states.statesList, st => st.id == currentAlumni.user.addresses[0]?.district?.state.id) 
            setState(state)
            setCity(currentAlumni.user.addresses[0]?.district?.id)
        }
    }, [states.getStatesStatus])

    useEffect(() => {
        if(currentAlumni?.user?.addresses?.length){
            let localData= _.findIndex(currentAlumni.user.addresses,a => a.type === 'correspondence') !== -1 ?
                _.find(currentAlumni.user.addresses,a => a.type === 'correspondence') : null
            
            let permanentData= _.findIndex(currentAlumni.user.addresses,a => a.type === 'permanent') !== -1 ?
                _.find(currentAlumni.user.addresses,a => a.type === 'permanent') : null

            if(permanentData) 
                setPerAddress(_.omit(permanentData, ['isActive']))
                
            if(localData) 
                setLocAddress(_.omit(localData, ['isActive']))
        }
    }, [currentAlumni])

    const updateAlumni = (e) => {
        let addresses = _.compact([{...perAddress, pincode:parseInt(perAddress.pincode)}, 
            {...locAddress, pincode:parseInt(locAddress.pincode)}
        ])
        dispatch(UpdateAddressAction({addresses, id:currentAlumni.user.id}))
    }

    const changeLocAddress = (e, type) => {
        if(type)
            setLocAddress(d => ({...d, [type]:e || null}))
        else
            setLocAddress(d => ({...d, [e.target.id]:e.target.value}))
    }

    const changePerAddress = (e, type) => {
        if(type)
            setPerAddress(d => ({...d, [type]:e || null}))
        else
            setPerAddress(d => ({...d, [e.target.id]:e.target.value}))
    }

    const handlePermanentAddress = () => {
        if(!perAsLocAddress)
            setPerAddress(d => ({...locAddress, type:d.type, id:d.id}))
        else
            setPerAddress({})
            
        setPerAsLocAddress(!perAsLocAddress)
    }

    // let permanentAddress = currentAlumni.user.addresses?.length ? _.find(currentAlumni.user.addresses,a => a.type === 'permanenet') : null
    return(
        <Drawer title='Update Address' width='80%' onClose={closeDrawer} visible={visible}>
                <Card bordered={false} bodyStyle={{padding:0}} loading={states.getStatesStatus === STATUS.FETCHING || batches.getBatchesStatus === STATUS.FETCHING}>
                    {states.getStatesStatus === STATUS.SUCCESS && batches.getBatchesStatus === STATUS.SUCCESS ?
                        <Form
                            from={from}
                            layout="horizontal"
                            size='large'
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={updateAlumni}
                        >
                            <Form.Item label={<Text style={{fontSize:18, fontWeight:'bold'}}>Current Address</Text>}>
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input.TextArea placeholder='address' id='address' value={locAddress.address} onChange={changeLocAddress} />
                            </Form.Item>
                            <Form.Item label="State" key={'state'} initialValue={currentAlumni.user.addresses[0]?.district?.state.id}>
                                <Select showSearch autoComplete='invalid' allowClear placeholder='select state' value={locAddress.stateId}
                                    onChange={(e) => changeLocAddress(e, 'stateId')}
                                >
                                    {states?.statesList?.map(state => 
                                        <Select.Option value={state.id} key={state.id}>{state.name}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item initialValue={selectedCity} preserve={false} key={formKey} label="City">
                                <Input value={locAddress.city} placeholder='city' onChange={changeLocAddress} id='city' />
                            </Form.Item>
                            <Form.Item label='Pin Code'>
                                <Input type='number' value={locAddress.pincode} id='pincode' onChange={changeLocAddress} placeholder='pin code'/>
                            </Form.Item>


                            <Form.Item label={<Text style={{fontSize:18, fontWeight:'bold'}}>Permanent Address</Text>}>
                                <Checkbox checked={perAsLocAddress} onChange={handlePermanentAddress}>Same as permanent address</Checkbox>
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input.TextArea placeholder='address' id='address' value={perAddress.address} onChange={changePerAddress} />
                            </Form.Item>
                            <Form.Item label="State" key={'state'} initialValue={currentAlumni.user.addresses[0]?.district?.state.id}>
                                <Select showSearch autoComplete='invalid' allowClear placeholder='select state' value={perAddress.stateId}
                                    onChange={(e) => changePerAddress(e, 'stateId')}
                                >
                                    {states?.statesList?.map(state => 
                                        <Select.Option value={state.id} key={state.id}>{state.name}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item initialValue={selectedCity} preserve={false} key={formKey} label="City">
                                <Input value={perAddress.city} placeholder='city' onChange={changePerAddress} id='city' />
                            </Form.Item>
                            <Form.Item label='Pin Code'>
                                <Input type='number' value={perAddress.pincode} id='pincode' onChange={changePerAddress} placeholder='pin code'/>
                            </Form.Item>


                            <Form.Item wrapperCol={ {offset: 5}}>
                                <Button loading={alumni.updateUserStatus === STATUS.FETCHING} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                        :
                        null
                    }
                </Card>
        </Drawer>
    )
}

const CustomInput = ({label, required, name, placeholder, type, hidden, value}) => {
    return(
        <Form.Item label={label} hidden={hidden} required={required} initialValue={value} name={name}>
            <Input placeholder={placeholder} required={required} type={type || 'text'}/>
        </Form.Item>
    )
}