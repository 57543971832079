import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "./Context";
import {
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "../services/api";
import { useCallback } from "react";
import { requestUserProfileAction } from "../redux/reducers/user";
import { useCheckStatus } from "../utils/useCheckStatus";
import { STATUS } from "../Constants";
import { getSingleInstituteAction } from "../redux/reducers/instituteStaff";

export const AppProvider = ({ children }) => {
  const [appstate, setAppState] = useState(false);

  const [isAuthenticated, setAuthenticated] = useState();
  const dispatch = useDispatch();

  const token = useRef();

  const loginSuccess = useCallback((data) => {
    // console.log("success login");
    setAuthorizationHeader(data.token);
    token.current = data.token;
    dispatch(requestUserProfileAction());
  }, [dispatch]);



  useEffect(() => {
    let isvalue = localStorage.getItem("@login/alumni");
    // console.log("value effect", isvalue);
    if (isvalue && isvalue !== "undefined") {
      loginSuccess({token:isvalue})
    } else {
      setAuthenticated(false);
      setAppState(true);
    }
  }, [dispatch, loginSuccess]);


  const { status, user } = useSelector((s) => ({
    status: s.user.status,
    user: s.user.user,
  }));

  const logout = useCallback(() => {
    setAuthenticated(false);
    localStorage.removeItem("@login/alumni");
    removeAuthorizationHeader();
  }, []);

  useEffect(() => {
    if(status === STATUS.SUCCESS && user){
      dispatch(getSingleInstituteAction({id:user.staff?.institute?.id}))
    }
  },[status, user, dispatch])

  useCheckStatus({
    status,
    onSuccess: () => {
      // console.log("success check status");

      localStorage.setItem("@login/alumni", token.current);
      setAuthenticated(true);
      setAppState(true);
    },
    onError: () => {
      // error
      setAuthenticated(false)
      setAppState(true)
    },
  });

  const value = useMemo(
    () => ({
      isAuthenticated,
      logout,
      loginSuccess,
      user,
    }),
    [isAuthenticated, loginSuccess, logout, user]
  );

  return (
    <AppContext.Provider value={value}>
      {appstate ? children : <div>Loading</div>}
    </AppContext.Provider>
  );
};
