import { Avatar, Button, Card, Col, Collapse, Drawer, Form, Input, Popover, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { STATUS } from '../../Constants'
import { deleteAlumnusAction, getAlumniListAction, memberVerifyAction, updateMemberAction, UpdateUserAction } from '../../redux/reducers/alumni'
import { getSingleInstituteAction } from '../../redux/reducers/instituteStaff'
import _ from 'lodash'
import Text from 'antd/lib/typography/Text'
import { UpdateStudentBatchModal } from '../ManageBatches/AssignStudentBatchModal'
import { DeleteOutlined, UserOutlined } from '@ant-design/icons'

export const ManageAlumni = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()

    const { alumni } =  useSelector((state) => ({
        alumni:state.alumni
    }))
    
    useEffect(() => {
        dispatch(getAlumniListAction({page:1, limit:10}))
    }, [dispatch])

    // useEffect(() => {
    //     if(alumni.getAlumniListStatus === STATUS.SUCCESS && alumni.alumniList.data?.length)
    //         history.push('/alumnis/'+ (params.pageNumber || 1))
    // }, [alumni.getAlumniListStatus])

    return(
        <>
            <CommonPageHeader title='Alumni'/>
            <br/>
            <Card >
                <Row>
                    <Col span={4}>
                        <Filters/>
                    </Col>
                    <Col span={20} style={{paddingLeft:'20px'}}> 
                        <AlumniTable alumniList={alumni.alumniList} history={history} params={params}/>
                    </Col>
                </Row>

                <br/><br/>
            </Card>
        </>
    )
}

export const AlumniTable = ({limit, batchList, pageChange}) => {
    const history = useHistory()
    const params = useParams()
    const dispatch = useDispatch()

    const [updateBatchModal, openUpdateBatchModal] = useState()

    const { alumni, alumniList, memberVerifyStatus, updateBatchStatus, deleteAlumnusStatus } =  useSelector((state) => ({
        alumni:state.alumni,
        alumniList:state.alumni.alumniList,
        memberVerifyStatus:state.alumni.memberVerifyStatus,
        updateBatchStatus:state.alumni.updateBatchStatus,
        deleteAlumnusStatus:state.alumni.deleteAlumnusStatus
    }))

    useEffect(() => {
        if(updateBatchStatus === STATUS.SUCCESS)
            openUpdateBatchModal(false)
    }, [updateBatchStatus])

    useEffect(() => {
        if(deleteAlumnusStatus === STATUS.SUCCESS){
            let page = alumniList.data.length === 1 ? alumniList.page - 1 : alumniList.page 
            dispatch(getAlumniListAction({page:page || 1}))
        }
    }, [deleteAlumnusStatus])
    
    const columns = [
        {title:'Name', dataIndex:'name', fixed:'left'},
        {title:'Image', dataIndex:'image', width:80},
        {title:'Batch', dataIndex:'batch', width:120,},
        {title:'Gender', dataIndex:'gender'},
        {title:'DOB', dataIndex:'dob'},
        {title:'Contact', dataIndex:'contact'},
        {title:'Email', dataIndex:'email'},
        // {title:'Address', dataIndex:'address'},
        // {title:'City', dataIndex:'city'},
        // {title:'State', dataIndex:'state'},
        // {title:'Pin', dataIndex:'pin'},
        {title:'Groups', dataIndex:'groups'},
        {title:'Info', dataIndex:'info'},
        {title:'Verified', dataIndex:'verified', width:100, fixed:'right'},
        {title:'actions', dataIndex:'actions', fixed:'right'}

    ]

    let verifyId = useRef(null)

    const verifyAlumni = (alumni, verified) => {
        verifyId.current = alumni.id
        dispatch(memberVerifyAction({id:alumni.id, isVerified:!verified}))
    }

    const handleAssignBatch = (alumni) => {
        openUpdateBatchModal(a => a ? null : alumni)
    }

    const handleDelete = (id) => {
        dispatch(deleteAlumnusAction({id}))
    }

    const data = alumniList?.data && alumniList.data.length ? 
        alumniList.data.map((alumni, i) => {
            const user = alumni.user 
            const address = alumni.user?.addresses[0] 
            
            return (alumni.user ? {
                key:i,
                image:<Avatar size='large' src={user.imageUrl} icon={<UserOutlined/>} />,
                name:user.name,
                gender:user.gender,
                dob:user.dob || '-',
                contact:
                    <Space direction='vertical'>
                        <Text>Primary: {user.contact}</Text>
                        {user.contactSecondary ? <Text>Secondary: {user.contactSecondary}</Text> : null}
                    </Space>,
                email:_.join(_.compact([user.email, user.emailSecondary]), ', '),
                // address:address?.address || '-',
                // city:address?.district?.name || address?.city || '-',
                // state:address?.district?.state?.name || '-',
                // pin:address?.pincode || '-',
                batch:alumni.batch ? <Text>{alumni.batch?.name} {alumni.batch.startyear ?  `(${alumni.batch.startyear +'-'+ (alumni.batch.endyear || '')})` : null}</Text> : null,
                groups:alumni.groups?.length ? _.join(alumni.groups.map(g => g.group?.name), ', ') : '',
                verified:alumni.isVerified ? <Tag color='green'>Verified</Tag> : <Tag color='red'>Unverified</Tag>,
                info: user.text_for_verification ? 
                    <Popover content={user.text_for_verification}>
                        <Button type='link' size='small'>Info</Button>
                    </Popover> : null,
                actions:
                    <>
                        {/* <Dropdown 
                            overlay={
                                <Menu>
                                    <Menu.Item icon={<EditOutlined />} onClick={() => handleAssignBatch(alumni)}>
                                        Update Batch
                                    </Menu.Item>
                                    <Menu.Item icon={alumni.isVerified ? <CloseOutlined /> : <CheckOutlined />} onClick={() => verifyAlumni(alumni, alumni.isVerified)}>
                                        {alumni.isVerified ? 'Unverify' : 'Verify'}
                                    </Menu.Item>
                                    <Menu.Item icon={<UserOutlined />} onClick={() => handleViewProfile(alumni)}>
                                        View Profile
                                    </Menu.Item>
                                    <Menu.Item icon={<DeleteOutlined />} onClick={() => handleDelete(alumni.id)}>
                                        Delete
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            actions
                        </Dropdown> */}
                        <Space wrap={true}>
                            <Button size='small' onClick={() => handleAssignBatch(alumni)}>
                                Update Batch
                            </Button>
                            <Tooltip title={!alumni.batch ? 'Assign batch before verifying user' :alumni.isVerified ? 'Unverify' : 'Verify'}>
                                <Button disabled={!alumni.batch} size='small' 
                                    loading={memberVerifyStatus === STATUS.FETCHING && verifyId.current === alumni.id} 
                                    onClick={() => verifyAlumni(alumni, alumni.isVerified)}
                                >
                                    {alumni.isVerified ? 'Unverify' : 'Verify'}
                                </Button>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <Button size='small' onClick={() => handleDelete(alumni.id)} icon={<DeleteOutlined/>} />
                            </Tooltip>
                        </Space>
                    </>
            } : null)})
        : []

    const _pageChange = (e) => {

        if(pageChange)
            pageChange(e.current)
        else
            dispatch(getAlumniListAction({page:e.current, limit:10}))
        // history.push('/alumnis/' + e.current)
    }

    console.log('limit', limit)
    return(
        <>
            <div style={{fontSize:'20px', marginBottom:'10px'}}>Alumni List</div>
            <Table bordered
                scroll={{ x: 1800 }}
                loading={alumni.getAlumniListStatus === STATUS.FETCHING || deleteAlumnusStatus === STATUS.FETCHING}
                pagination={{total:alumniList?.totalCount, defaultPageSize:limit || 10, 
                    current:alumniList?.page ? parseInt(alumniList?.page) : 1, showSizeChanger:false,
                    position:['bottomCenter', 'topCenter']
                }}
                dataSource={data}
                columns={columns}
                // rowSelection={{type: 'checkbox'}}  
                onChange={e => _pageChange(e)}  
            />
            {updateBatchModal ? <UpdateStudentBatchModal visible={updateBatchModal} alumni={updateBatchModal} closeModal={handleAssignBatch} /> : null}
        </>
    )
}

const Filters = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const [form] = Form.useForm() 
    const {user, institute} = useSelector((state) => ({
        user:state.user,
        institute:state.instituteStaff
    }))

    const defaultFilters = {searchKeys:[], searchVals:[]}
    const [searchKeys, setSearchKeys] = useState({})
    const [filters, setFilters] = useState(defaultFilters)
    
    // useEffect(() => {
    //     dispatch(getSingleInstituteAction({id:user.user.staff.institute.id}))
    // }, [])

    const filterAlumni = () => {
        let data = {page:1, limit:10, ...filters}
        dispatch(getAlumniListAction(data))
    }

    const resetfilter = () => {
        setFilters(defaultFilters)
        setSearchKeys({})  
        form.resetFields()
        dispatch(getAlumniListAction({page:1, limit:10})) 
    }

    const changeFilter = (d) => {
        let data = {...filters, ...d}
        setFilters(data)
    }

    const searchText = (d) => {
        let keys = {...searchKeys}

        if(d.key === 'isVerified'){
            let val = d.val
            let key = d.key
            let data = {[key]:val}

            Object.assign(keys, data)

        }else{
            let val = d.val || ''
            let key = d.key

            if(val){
                let data = {[key]:val}
                Object.assign(keys, data)
            }
            else{
                keys = key === 'isVerified' ? keys : _.omitBy(keys,(d, k) => k == key)
            }
        }

        setSearchKeys(keys)
        setFilters({...filters, searchKeys:_.keysIn(keys), searchVals:_.map(keys,d => d)})
    }

    return(
        <>
            <div style={{fontSize:'20px', marginBottom:'10px'}}>Filters</div>
            <div style={{position:'relative', height:'500px', overflow:'auto', padding:'1px'}}>
                <Form form={form} key='form'>
                    <Collapse>
                        <Collapse.Panel header='Verified Status' key='8'>
                            <Form.Item style={{marginBottom:'5px'}} name='isVerified'>
                                <Select placeholder='Select Verified Status'
                                    onChange={e => searchText({val:e, key:'isVerified'})}
                                >
                                    <Select.Option value={true}>Verified</Select.Option>
                                    <Select.Option value={false}>Unverified</Select.Option>
                                </Select>
                            </Form.Item>
                        </Collapse.Panel>
                        <Collapse.Panel header='Alumni Name' key='1'>
                            <Form.Item style={{marginBottom:'5px'}} name='name'
                                onChange={e => searchText({val:e.target.value, key:'name'})}
                            >
                                <Input placeholder='Enter Name' />
                            </Form.Item>
                        </Collapse.Panel>
                        <Collapse.Panel header='Contact' key='2'>
                            <Form.Item style={{marginBottom:'5px'}} name='contact'
                                onChange={e => searchText({val:e.target.value, key:'contact'})}
                            >
                                <Input placeholder='Enter Contact' type='number' />
                            </Form.Item>
                        </Collapse.Panel>
                        <Collapse.Panel header='Email' key='3'>
                            <Form.Item style={{marginBottom:'5px'}}  name='email'
                                onChange={e => searchText({val:e.target.value, key:'email'})}
                            >
                                <Input placeholder='Enter Email' />
                            </Form.Item>
                        </Collapse.Panel>
                        <Collapse.Panel header='Gender' key='5'>
                            <Form.Item style={{marginBottom:'5px'}} name='gender'>
                                <Select placeholder='Select Gender'
                                    onChange={e => searchText({val:e, key:'gender'})}
                                >
                                    <Select.Option value='male'>Male</Select.Option>
                                    <Select.Option value='female'>Female</Select.Option>
                                </Select>
                            </Form.Item>
                        </Collapse.Panel>
                        <Collapse.Panel header='Batch' key='6'>
                            {institute.getStatus == STATUS.SUCCESS ?
                                <Form.Item style={{marginBottom:'5px'}} name='batchIds'>
                                    <Select placeholder='Select Batches' onChange={(e) => changeFilter({batchIds:e})} mode='multiple'>
                                        {institute.singleInstitute.batches?.length ? 
                                            institute.singleInstitute.batches.map(batch => 
                                                <Select.Option value={batch.id} key={batch.id}>{batch.name}</Select.Option>
                                            )
                                            :
                                            null
                                        }
                                    </Select>
                                </Form.Item>
                                :
                                null
                            }
                        </Collapse.Panel>
                        <Collapse.Panel header='Group' key='7'>
                            {institute.getStatus == STATUS.SUCCESS ?
                                    <Form.Item style={{marginBottom:'5px'}} name='groupIds'>
                                        <Select placeholder='Select Groups' allowClear onChange={(e) => changeFilter({groupIds:e})} mode='multiple'>
                                            {institute.singleInstitute.groups?.length ? 
                                                institute.singleInstitute.groups.map(batch => 
                                                    <Select.Option value={batch.id} key={batch.id}>{batch.name}</Select.Option>
                                                )
                                                :
                                                null
                                            }
                                        </Select>
                                    </Form.Item>
                                :
                                null
                            }
                        </Collapse.Panel>
                    </Collapse>
                    <div style={{display:'flex', position:'sticky', bottom:0, zIndex:'10', background:'white'}}>
                        <Button type='primary' ghost onClick={filterAlumni} block>Apply</Button>
                        <Button danger onClick={resetfilter} block>Reset</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

const SearchKeys = ({searchKeyValue, placeholder}) => {
    return(
        <>
            <Form.Item style={{marginBottom:'5px'}} name='searchVal'>
                <Input allowClear placeholder={placeholder} />
            </Form.Item>
            <Form.Item hidden={true} initialValue={searchKeyValue} name='searchKey'>
                <Input/>
            </Form.Item>
        </>
    )
}