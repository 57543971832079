import { Button, Card, Input, Form, Select, DatePicker, Modal, Table, Space, Tooltip } from 'antd';
import moment from 'moment';
import {RoleType, STATUS} from '../../Constants'
import {useDispatch, useSelector} from 'react-redux' 
import { useState, useEffect } from 'react';

import { addInstituteStaffAction, getSingleInstituteAction, editInstituteStaffAction, resetEditStatusAction, deleteInstituteStaffAction } from '../../redux/reducers/instituteStaff'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import _ from 'lodash';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

export const InstituteStaffModal = (props) => {
  const dispatch = useDispatch()
  const [currentView, changeCurrentView] = useState({type: 'list', data: ''})
  const {data} = useSelector(s => ({
      data: s.instituteStaff,
  }))

  useEffect(() => {
    if(currentView.type === 'list'){
      dispatch(getSingleInstituteAction({id: props.selectedData.id}))
    }
  },[currentView, dispatch, props.selectedData])

  return(
    <Modal visible={props.instituteModal} footer={null} width='1000px' onOk={() => console.log('')} onCancel={() => props.closeModal()}>
      {currentView.type == 'list' ? 
        <ListInstituteStaff changeView={(d) => changeCurrentView({type: d.type, data: d.data})} data={{data: data.singleInstitute, status: data.getStatus}}/>
      : 
        <AddInstituteStaff preSelected={currentView.data} changeView={(d) => changeCurrentView({type: d.type, data: d.data})} data={{data: data.singleInstitute, status: data.getStatus}}/>
      }
    </Modal>
  )
}

export function ListInstituteStaff(props){
  const dispatch = useDispatch()
	const [modal, contextHolder] = Modal.useModal()


  const handleDelete = (d) => {
    modal.confirm({
      title:'Are you Sure ?',
      onOk:() => dispatch(deleteInstituteStaffAction({id: d.id})),
		  okText:'Delete'
    })
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: d => d.user.name
    },
    {
      title: 'Contact',
      key: 'contact',
      render: d => d.user.contact
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Role',
      key: 'role',
      render: d => d.user.role
    },
    {
      title: 'Username',
      key: 'username',
      render: d => d.user.username
    },
    {
      title: 'Action',
      key: 'action',
      render: d => 
            <Space>
              <Tooltip title='Edit'>
                <Button size='small' onClick={() => props.changeView({type: 'edit', data: d})}><EditOutlined /></Button>
              </Tooltip>
              <Tooltip title='Delete'>
                <Button size='small' danger onClick={() => handleDelete(d)}><DeleteOutlined /></Button>
              </Tooltip>
            </Space>
    },
  ];
  return (
    <div style={{padding: '20px'}}>
        <Card 
          title={
            <div>
              <span>
                LIST INSTITUTE STAFF
              </span>
              <span>
                <Button style={{float: 'right'}} onClick={() => props.changeView({type: 'add', data: ''})}>Add Staff</Button>
              </span>
            </div>
          } 
          bordered={false} 
          style={{ width: '100%' }}
        >
        <div>
          <Table 
            loading={props.data.status === STATUS.FETCHING}
            columns={columns} 
            dataSource={props.data.data ? 
              _.map(props.data.data.staffs, s => {
                return Object.assign({}, s, {key: s.id})
              })
              :
              []
            } 
          />
        </div>
        </Card>
        {contextHolder}
    </div> 
  );
}

export function AddInstituteStaff(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const [stateData, stateChange] = useState({contact: '', name: '', code: '', instituteId: props.data.data.id, role:"", username:""})

  const {data} = useSelector(s => ({
      data: s.instituteStaff,
  }))

  const curretStateData = () => {
    stateChange({
      contact: props.preSelected.user.contact ? props.preSelected.user.contact : '', 
      name: props.preSelected.user.name ? props.preSelected.user.name : '', 
      code: props.preSelected.code ? props.preSelected.code : '', 
      instituteId: props.data.data.id, 
      role: props.preSelected.user.role ? props.preSelected.user.role : '',
      username: props.preSelected.user.username ? props.preSelected.user.username : '',
    })
  }

  useEffect(() => {
    if(props.preSelected){
      curretStateData()
    }
  }, [props.preSelected])

  useEffect(() => {
    if(data.addStatus == "SUCCESS"){
      dispatch(resetEditStatusAction())
      props.changeView({type: 'list', data: ''})
      stateChange({contact: '', name: '', code: '', instituteId: props.data.data.id, role:"", username:""})
    }  
  }, [data.addStatus])

  const onFinish = () => {
    if(props.preSelected){
      let changedData = {
        "id": props.preSelected.id,
        "code": stateData.code,
        "user": {
          "id": props.preSelected.user.id,
          "name": stateData.name,
          "contact": stateData.contact,
          "username": stateData.username
        }
      }
      dispatch(editInstituteStaffAction(changedData))
    }else{
      dispatch(addInstituteStaffAction({
          contact: stateData.contact,
          name: stateData.name,
          code: stateData.code,
          instituteId: stateData.instituteId, 
          role: stateData.role,
          username: stateData.username,
        })
      )
    }
  };

  let selectFormData = (value, type) => {
    stateChange({...stateData, [type]: value})
  }

  const disabled = !stateData.name || !stateData.role || !stateData.contact
  return (
    <div style={{padding: '20px'}}>
      <Card 
        title={
          <div>
            <span>
              {props.preSelected ? "EDIT INSTITUTE STAFF" : "ADD INSTITUTE STAFF"}
            </span>
            <span>
              <Button style={{float: 'right'}} onClick={() => props.changeView({type: 'list', data: ''})}>List Staff</Button>
            </span>
          </div>
        } 
        bordered={false} 
        style={{ width: '100%' }}
      >
        <Form {...formItemLayout} layout="horizontal">
          <Form.Item label="Name" required>
            <Input placeholder="Input Name" onChange={(e) => selectFormData(e.target.value, 'name')} defaultValue={stateData.name} value={stateData.name}/>
          </Form.Item>
          <Form.Item label="Role" required>
            <Select onChange={(e) => selectFormData(e, 'role')} defaultValue={stateData.role} value={stateData.role}>
              <Select.Option value=''>Select Role</Select.Option>
              {_.map(RoleType, (s,i) => (
                <Select.Option key={i} value={s}>{s}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Contact" required>
            <Input placeholder="Input Contact" type={'number'} onChange={(e) => selectFormData(e.target.value, 'contact')} defaultValue={stateData.contact} value={stateData.contact}/>
          </Form.Item>
          <Form.Item label="Code">
            <Input placeholder="Input Code" onChange={(e) => selectFormData(e.target.value, 'code')} defaultValue={stateData.code} value={stateData.code}/>
          </Form.Item>
          <Form.Item label="Username">
            <Input placeholder="Input Username" onChange={(e) => selectFormData(e.target.value, 'username')} defaultValue={stateData.username} value={stateData.username}/>
          </Form.Item>
          <div style={{textAlign: 'center', paddingBottom: '20px'}}>
            <Button disabled={disabled} type="primary" onClick={() => onFinish()}>
              Register
            </Button>
          </div>
        </Form>
      </Card>
    </div> 
  );
}


