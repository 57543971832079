import { SelectOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Radio, Space, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Text from 'antd/lib/typography/Text'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { NOTIFIABLE_TYPES, STATUS } from '../../Constants'
import { FormReducer } from '../../utils/FormReducer'
// import { SelectStudentModal } from '../SearchStudent/SelectStudentModal'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {AlumniModal} from '../ManageAlumni/AlumniModal'
import { SelectGroupModal } from './SelectGroupModal'
import { SelectBatchModal } from './SelectBatchModal'
import { sendNotificationAction } from '../../redux/reducers/notifications'

export const Notifications = () => {
    const [notifiableType, changeType] = useState([NOTIFIABLE_TYPES.ALL_ALUMNI])
    
    const [alumniModal, openAlumniModal] = useState()
    const [alumnisList, setAlumnis] = useState([])
    
    const [groupsList, setGroups] = useState([])
    const [groupsModal, openGroupsModal] = useState()

    const [batchesList, setBatches] = useState([])
    const [batchesModal, openBatchesModal] = useState()

    const sendTo = (e) => {
        changeType([e.target.value])
    }

    const handleAlumniModal = () => {
        openAlumniModal(!alumniModal)
    }

    const handleSelectedAlumnis = (data) => {
        setAlumnis(data)
    }

    const handleSelectedGroups = (data) => {
        setGroups(data)
    }

    const handleGroupsModal = () => {
        openGroupsModal(!groupsModal)
    }

    const handleBatchesModal = () => {
        openBatchesModal(!batchesModal)
    }

    const handleSelectedBatches = (data) => {
        setBatches(data)
    }

    return(
        <div>
            <CommonPageHeader title='Notifications' />
            <br/>
            <Card>
                <Form layout='vertical'>
                    <Form.Item label={<b>Send to</b>}>
                        <Radio.Group defaultValue={NOTIFIABLE_TYPES.INSTITUTE} value={notifiableType[0]} onChange={sendTo}>
                            <Radio value={NOTIFIABLE_TYPES.INSTITUTE}>Institute</Radio>
                            <Radio value={NOTIFIABLE_TYPES.ALL_ALUMNI}>All Alumni</Radio>
                            <Radio value={NOTIFIABLE_TYPES.USERS}>Alumnus</Radio>
                            <Radio value={NOTIFIABLE_TYPES.ALL_STAFF}>All Staff</Radio>
                            <Radio value={NOTIFIABLE_TYPES.USERS+'-staff'}>Staff</Radio>
                            <Radio value={NOTIFIABLE_TYPES.GROUPS}>Groups</Radio>
                            <Radio value={NOTIFIABLE_TYPES.BATCHES}>Batches</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {notifiableType[0] === NOTIFIABLE_TYPES.USERS ? 
                        <Space size='large' style={{marginBottom:20}}>
                            <Button onClick={handleAlumniModal} icon={<SelectOutlined />}>Select Alumni</Button>
                            {alumnisList?.length ?
                                <>
                                    <Space>
                                        <b>Selected Alumni Count:</b>
                                        <Tooltip title='Remove all'>
                                            <Tag onClose={() => handleSelectedAlumnis([])} closable>{alumnisList.length} </Tag>
                                        </Tooltip>
                                    </Space>
                                </>
                                : null
                            }
                        </Space>
                        :notifiableType[0] === NOTIFIABLE_TYPES.GROUPS ?
                            <Space size='large' style={{marginBottom:20}}>
                                <Button onClick={handleGroupsModal} icon={<SelectOutlined />}>Select Groups</Button>
                                {groupsList?.length ? 
                                    <>
                                        <Space>
                                            <b>Selected Groups:</b>
                                            <Tooltip title='Remove all'>
                                                <Tag onClose={() =>handleSelectedGroups([])} closable>{groupsList.length} </Tag>
                                            </Tooltip>
                                        </Space>
                                    </>
                                    : null
                                }
                                <br/><br/>
                            </Space>
                            :notifiableType[0] === NOTIFIABLE_TYPES.BATCHES ?
                                <Space size='large' style={{marginBottom:20}}>
                                    <Button onClick={handleBatchesModal} icon={<SelectOutlined />}>Select Batches</Button>
                                    {batchesList?.length ? 
                                        <>
                                            <Space>
                                                <b>Selected Batches:</b>
                                                <Tooltip title='Remove all'>
                                                    <Tag onClose={() =>handleSelectedBatches([])} closable>{batchesList.length} </Tag>
                                                </Tooltip>
                                            </Space>
                                        </>
                                        : null
                                    }
                                    <br/><br/>
                                </Space>
                                :
                                null
                    }
                </Form>
                <Card>
                    <MessageBox 
                        alumnisList={alumnisList.map(d => d.user.id)} 
                        groupsList={groupsList.map(d => d.id)} 
                        batchesList={batchesList.map(d => d.id)} 
                        notifiableTypesArray={notifiableType} 
                    />
                </Card>
                {alumniModal ? <AlumniModal visible={alumniModal} defaultAlumnis={alumnisList} closeModal={handleAlumniModal} selectedAlumnis={handleSelectedAlumnis} /> : null }
                {groupsModal ? <SelectGroupModal visible={groupsModal} defaultGroups={groupsList} closeModal={handleGroupsModal} selectedGroups={handleSelectedGroups} /> : null }
                {batchesModal ? <SelectBatchModal visible={batchesModal} defaultBatches={batchesList} closeModal={handleBatchesModal} selectedBatches={handleSelectedBatches} /> : null }
                
            </Card>
        </div>
    )
}

export const MessageBox = ({notifiableTypesArray, alumnisList, groupsList, batchesList}) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const {institute, sendNotificationStatus} = useSelector(state => ({
        institute:state.user.user.staff.institute,
        sendNotificationStatus:state.notifications.sendNotificationStatus
    }))

    let initialData = {pushToEmail:false, pushToSms:false, pushToApp:true}
    const [formData, changeFormData] = useReducer(FormReducer, initialData)

    const [editorMessage, changeEditorMessage] = useState()
    const [showEditor, toggleEditor] = useState(false)

    const getValues = useCallback(() => {
        let type = notifiableTypesArray[0]
        const notifiableIdsArray = type === NOTIFIABLE_TYPES.USERS ? 
            alumnisList 
            : type === NOTIFIABLE_TYPES.BATCHES ?
                batchesList
                :type === NOTIFIABLE_TYPES.GROUPS ?
                    groupsList : [institute.id]

        const value = {
            notifiableTypesArray:notifiableTypesArray[0] === 'USER_IDS-staff' ? [NOTIFIABLE_TYPES.USERS] : notifiableTypesArray , 
            notifiableIdsArray:[notifiableIdsArray]
        }
        return value
    }, [alumnisList, batchesList, groupsList, institute.id, notifiableTypesArray])

    useEffect(() => {
        if(notifiableTypesArray?.[0]){
            let value = getValues()
            
            changeFormData({type:'merge', value})
            
        }
    }, [notifiableTypesArray, alumnisList, groupsList, batchesList, institute.id, getValues])

    useEffect(() => {
        if(sendNotificationStatus === STATUS.SUCCESS){
            const value = getValues()
            form.resetFields()
            changeFormData({type:'reset', value:{...initialData, ...value}})
            changeEditorMessage('')
        }
    }, [sendNotificationStatus])

    const messageType = (e) => {
        let type = e.target.value
        let obj = {pushToSms:false, pushToEmail:false, pushToApp:false}
        if(type === 'sms'){
            obj = {...obj, pushToSms:true}
        }else if(type === 'email'){
            obj = {...obj, pushToEmail:true}
        }else if(type === 'notification'){
            obj = {...obj, pushToApp:true}
        }else if(type === 'sms-notify'){
            obj = {...obj, pushToSms:true, pushToApp:true}
        }

        changeFormData({type:'merge', value:obj})
    }

    const changeTitle = (e) => {
        changeFormData({type:'title', value:e.target.value})
    }

    const changeMessage = (e) => {
        changeFormData({type:'body', value:e.target.value})
    }

    const sendMessage = () => {
        let data = {...formData, body:showEditor ? editorMessage : formData.body  }
        dispatch(sendNotificationAction(data))
    }

    const handleEditorToggle =  () => {
        toggleEditor(!showEditor)
    }

    const handleEditorMessage = (e) => {
        let msg = e === '<p><br></p>' ? '' : e
        changeEditorMessage(msg)
    }

    return(
        <Form form={form} layout='vertical' onFinish={sendMessage}>
            <Form.Item label={<b>Message type</b>} name='messageType'>
                <Radio.Group defaultValue='notification' onChange={messageType}>
                    <Radio value='notification'>Notification</Radio>
                    <Radio value='sms'>SMS</Radio>
                    <Radio value='sms-notify'>Notification + SMS</Radio>
                    <Radio value='email'>Email</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label={<b>Title</b>} name='title'>
                <Input placeholder='Title' rows={4} onChange={changeTitle}/>
            </Form.Item>
            {formData.pushToEmail ? 
                <Form.Item required 
                    label={<b>Message <Button onClick={handleEditorToggle} size='small'>{showEditor ? 'Text' : 'Editor'}</Button></b>} 
                >
                    {showEditor ? 
                        <ReactQuill placeholder='Type message...' onChange={handleEditorMessage} value={editorMessage} />
                        :
                        <TextArea onChange={changeMessage} placeholder='Type message...' value={formData?.body} rows={4}/>
                    }
                </Form.Item>
                :
                <Form.Item required 
                    label={<b>Message</b>} 
                >
                    <TextArea onChange={changeMessage} placeholder='Type message...' value={formData?.body} rows={4}/>
                </Form.Item>
            }
            <Form.Item>
                <Button disabled={!formData.notifiableTypesArray || (!formData.body && !editorMessage)} 
                    htmlType='submit' type='primary' size='large' style={{width:'100px'}}
                >
                    Send
                </Button>
            </Form.Item>
        </Form>
    )
}