import { Avatar, Card, Image, Tooltip } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { STATUS } from '../../Constants'
import { getAllPostsAction } from '../../redux/reducers/posts'
import { EditOutlined, EllipsisOutlined, CheckOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta'

export const ManagePosts = () => {
    const dispatch = useDispatch()

    const {getAllPostsStatus, allPosts} = useSelector(state => ({
        getAllPostsStatus:state.posts.getAllPostsStatus,
        allPosts:state.posts.allPosts
    }))

    useEffect(() => {
        dispatch(getAllPostsAction())
    }, [dispatch])

    return(
        <div>
            <CommonPageHeader
                title='Manage Posts'
            />
            <br/>

            <Card loading={getAllPostsStatus === STATUS.FETCHING}>
                {getAllPostsStatus === STATUS.SUCCESS && allPosts?.length ? 
                    <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
                        {allPosts.map(post => 
                            <PostCard key={post.id} currentPost={post}/>
                        )}
                    </div>
                    :
                    null
                }
            </Card>
        </div>
    )
}

const PostCard = ({currentPost}) => {

    return(
        <div title='' style={{margin:15, width:350, border:'1px solid #EAEDED'}}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height:'100%'}}>
                <div>
                    <Image style={{objectFit:'cover', width:350, height:250}}
                        alt="example"
                        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                    />
                    <div style={{padding:10,}}>
                        <Meta style={{overflow:'hidden'}}
                            avatar={<Avatar size='sm' src="https://joeschmoe.io/api/v1/random" />}
                            title={currentPost.user.name}
                            description={currentPost.post_text.length > 300 ? currentPost.post_text.substring(1, 300)+'...' : currentPost.post_text}
                        />
                    </div>
                </div>
                <div style={{display:'flex', alignItems:'center', color:'#8C8C8C', fontSize:18}} key='actions'>
                    <div style={{flexGrow:1}}>
                        <Tooltip title='Verify Post'>
                            <div style={{padding:8, textAlign:'center'}}>
                                <CheckOutlined key="verify" />
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{flexGrow:1}}>
                        <Tooltip title='Edit'>
                            <div style={{padding:8, textAlign:'center'}}>
                            <EditOutlined key="edit" />
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{flexGrow:1}}>
                        <Tooltip title='More'>
                            <div style={{padding:8, textAlign:'center'}}>
                            <EllipsisOutlined key="more" />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <br/><br/>
        </div>
    )
}