import { apiClient, URIS } from ".";

const requestUserApi = (payload) => apiClient.get(URIS.ME, payload);
const addBatchApi = (payload) => apiClient.post(URIS.ADD_BATCH, payload);
const getBatchesApi = (payload) => apiClient.get(URIS.GET_BATCHES, payload);

const getInstituteApi = (payload) => apiClient.get(URIS.GET_INSTITUTES, payload);
const addInstituteApi = (payload) => apiClient.post(URIS.ADD_INSTITUTE, payload);
const editInstituteApi = (payload) => apiClient.patch(URIS.INSTITUTE, payload);
const deleteInstituteApi = (payload) => apiClient.delete(URIS.INSTITUTE, payload);

const addInstituteStaffApi = (payload) => apiClient.post(URIS.ADD_INSTITUTE_STAFF, payload);
const editInstituteStaffApi = (payload) => apiClient.patch(URIS.STAFF, payload);
const deleteInstituteStaffApi = (payload) => apiClient.delete(URIS.STAFF, payload);

const requestStateApi = (payload) => apiClient.get(URIS.STATES, payload);
const addAlumniApi = (payload) => apiClient.post(URIS.ADD_ALUMNI, payload);
const getAlumniApi = (payload) => apiClient.get(URIS.GET_ALUMNI, payload);
const searchAlumnis = (payload) => apiClient.get(URIS.SEARCH_ALUMNIS, payload);
const addGroupApi = (payload) => apiClient.post(URIS.ADD_GROUP, payload);

const getSingleInstituteApi = (payload) => apiClient.get(URIS.GET_SINGLE_INSTITUTE, payload)
const addEducationApi = (payload) => apiClient.post(URIS.ADD_EDUCATION, payload)
const editEducationApi = (payload) => apiClient.patch(URIS.ADD_EDUCATION, payload)
const assignGroupsApi = (payload) => apiClient.post(URIS.ASSIGN_GROUPS, payload)
const updateUserApi = (payload) => apiClient.patch(URIS.UPDATE_USER, payload)
const deleteGroupApi = (payload) => apiClient.delete(URIS.GROUP, payload) 
const updateBatchApi = (payload) => apiClient.patch(URIS.UPDATE_BATCH, payload) 
const updateGroupApi = (payload) => apiClient.patch(URIS.GROUP, payload) 
const removeGroupApi = (payload) => apiClient.post(URIS.REMOVE_GROUP, payload) 
const addAlumniExcelApi = (payload) => apiClient.post(URIS.ADD_ALUMNI_EXCEL, payload)
const updateMemberApi = (payload) => apiClient.patch(URIS.UPDATE_MEMBER, payload)


const deleteAlumniEducationApi = (payload) => apiClient.delete(URIS.ADD_EDUCATION, payload) 

const addExperienceApi = (payload) => apiClient.post(URIS.ALUMNI_EXPERIENCE, payload)
const editExperienceApi = (payload) => apiClient.patch(URIS.ALUMNI_EXPERIENCE, payload)
const deleteAlumniExperienceApi = (payload) => apiClient.delete(URIS.ALUMNI_EXPERIENCE, payload) 
const getalumniListApi = (payload) => apiClient.get(URIS.GET_ALUMNI_LIST, payload) 
const updateAddressApi = (payload) => apiClient.patch(URIS.UPDATE_ADDRESS, payload) 
const getAllPostsApi = (payload) => apiClient.get(URIS.GET_ALL_POSTS, payload)
const memberVerifyApi = (payload) => apiClient.patch(URIS.VERIFY_MEMBER, payload)
const addAchievementApi = (payload) => apiClient.post(URIS.ADD_ACHIEVEMENT, payload)
const updateAchievementApi = (payload) => apiClient.patch(URIS.ADD_ACHIEVEMENT, payload)
const deleteAchievementApi = (payload) => apiClient.delete(URIS.ADD_ACHIEVEMENT, payload)
const sendNotificationApi = (payload) => apiClient.post(URIS.SEND_NOTIFICATIONS, payload)
const updateAlumnusBatchApi = (payload) => apiClient.patch(URIS.GET_ALUMNI, payload)
const getNotificationsApi = (payload) => apiClient.get(URIS.GET_NOTIFICATIONS, payload)
const deleteAlumnusApi = (payload) => apiClient.delete(URIS.VERIFY_MEMBER, payload)

export const apis = {
    deleteAlumnusApi,
    getNotificationsApi,
    updateAlumnusBatchApi,
    sendNotificationApi,
    deleteAchievementApi,
    updateAchievementApi,
    addAchievementApi,
    memberVerifyApi,
    getAllPostsApi,
    requestUserApi,
    addInstituteApi,
    requestStateApi,
    addBatchApi,
    getBatchesApi,
    getInstituteApi,
    addInstituteStaffApi,
    getSingleInstituteApi,
    addAlumniApi,
    getAlumniApi,
    searchAlumnis,
    addGroupApi,
    addEducationApi,
    editEducationApi,
    assignGroupsApi,
    editInstituteApi,
    deleteInstituteApi,
    editInstituteStaffApi,
    deleteInstituteStaffApi,
    updateUserApi,
    deleteGroupApi,
    updateBatchApi,
    updateGroupApi,
    removeGroupApi,
    addAlumniExcelApi,
    updateMemberApi,
    deleteAlumniEducationApi,
    addExperienceApi,
    editExperienceApi,
    deleteAlumniExperienceApi,
    getalumniListApi,
    updateAddressApi,
}