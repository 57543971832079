import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { STATUS } from "../../../Constants"
import { SuccessMessage } from "../../../Constants/CommonAlerts"
import { URIS } from "../../../services/api"
import { apis } from "../../../services/api/apis"

export const addBatchAction = createAsyncThunk(
    URIS.ADD_BATCH,
    async (payload, thunkAPI) => {
      const response = await apis.addBatchApi(payload)
      const { ok, problem, data } = response
      if (ok) {
        return data
      } else {
       return thunkAPI.rejectWithValue(problem)
      }
    }
  )

export const updateBatchAction = createAsyncThunk(
    'batch/update',
    async (payload, thunkAPI) => {
      const response = await apis.updateBatchApi(payload)
      const { ok, problem, data } = response
      if (ok) {
        return data
      } else {
       return thunkAPI.rejectWithValue(problem)
      }
    }
  )

export const getBatchesRequest = createAsyncThunk(
    URIS.GET_BATCHES,
    async (payload, thunkAPI) => {
      const response = await apis.getBatchesApi(payload)
      const { ok, problem, data } = response
      if (ok) {
        return data
      } else {
       return thunkAPI.rejectWithValue(problem)
      }
    }
  )

const initialState = {
    addBatchStatus: STATUS.NOT_STARTED,
    getBatchesStatus: STATUS.NOT_STARTED
}

const userSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    setActiveStaff: (state, action) => {
      state.activeStaff = action.payload
      state.user = action.payload?.user
    },
  },
  extraReducers:{
      [addBatchAction.pending]:(state, action) => {
        state.addBatchStatus = STATUS.FETCHING
      },
      [addBatchAction.fulfilled]: (state, action) => {
          SuccessMessage('Batch Added')
          state.addBatchStatus = STATUS.SUCCESS
          state.batches.push(action.payload)
      },
      [addBatchAction.rejected] : (state, action) => {
        state.addBatchStatus = STATUS.FAILED
      },

      [getBatchesRequest.pending]:(state, action) => {
        state.getBatchesStatus = STATUS.FETCHING
      },
      [getBatchesRequest.fulfilled]: (state, action) => {
          state.getBatchesStatus = STATUS.SUCCESS
          state.batches=action.payload.batches
      },
      [getBatchesRequest.rejected] : (state, action) => {
        state.getBatchesStatus = STATUS.FAILED
      },

      [updateBatchAction.pending]:(state, action) => {
        state.updateBatchStatus = STATUS.FETCHING
      },
      [updateBatchAction.fulfilled]:(state, action) => {
        SuccessMessage('Batch Updated')
        state.updateBatchStatus = STATUS.SUCCESS
        state.batches = state.batches?.map(b => b.id == action.payload.id ? action.payload : b)
      },
      [updateBatchAction.rejected]:(state, action) => {
        state.updateBatchStatus = STATUS.FAILED
      },
  },
})

export const batchReducer = userSlice.reducer