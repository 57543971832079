import { CloseCircleTwoTone, EditOutlined, EditTwoTone, LoadingOutlined, PlusOutlined, UserOutlined, LinkOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Tag,
  Tooltip,
  Avatar,
  Card,
  Col,
  Descriptions,
  Empty,
  Row,
  Form,
  Button,
  Select,
  Space,
  Image,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useRef, useState } from "react";
import {
  getAlumniAction,
  resetEducationStatusAction,
  removeGroupAction,
  updateMemberAction,
  resetExperienceStatusAction
} from "../../redux/reducers/alumni";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { STATUS } from "../../Constants";
import { getSingleInstituteAction } from "../../redux/reducers/instituteStaff";
import { AssignStudentGroups } from "../../components/AssignStudentGroups";
import _ from "lodash";
import { UpdateProfile } from "../../components/UpdateAlumniProfile";
import FormItem from "antd/lib/form/FormItem";
import { UpdateAddress } from "../../components/UpdateAlumniAddress";
import { Experience } from "./Experience";
import { getStatesAction } from "../../redux/reducers/states";

export const AlumniProfile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const params = useParams();
  const { alumni, instituteId, institute, batches, updateMemberStatus } = useSelector((state) => ({
    alumni: state.alumni,
    instituteId: state.user.user.staff.institute.id,
    institute: state.instituteStaff,
    batches:state.batches,
    updateMemberStatus:state.alumni.updateMemberStatus
  }));

  const [updateProfileDrawer, setUpdateProfileDrawer] = useState(false)
  const [updateAddressDrawer, setUpdateAddressDrawer] = useState(false)
  const [updateBatch, setUpdateBatch] = useState(false)

  // useEffect(() => {
  //   dispatch(getSingleInstituteAction({ id: instituteId }));
  //   // dispatch(getBatchesRequest({id:instituteId}))
  // }, [])

  useEffect(() => {
    dispatch(resetEducationStatusAction());
    dispatch(resetExperienceStatusAction());
    dispatch(getAlumniAction({ id: params.id }));
  }, [params.id, dispatch]);

  useEffect(() => {
    if(alumni.updateAddressStatus === STATUS.SUCCESS)
      setUpdateAddressDrawer(false)

    if(alumni.updateUserStatus === STATUS.SUCCESS)
      setUpdateProfileDrawer(false)
  }, [alumni.updateUserStatus, alumni.updateAddressStatus])

  useEffect(() => {
    if(alumni.updateMemberStatus === STATUS.SUCCESS){
      setUpdateBatch(false)
      form.resetFields()
    }
  }, [alumni.updateMemberStatus])

  const _updateBatch = (data) => {
    let batch = _.find(institute.singleInstitute.batches,d => d.id === data.batchId)
    dispatch(updateMemberAction({id:alumni.currentAlumni.id, batchId:data.batchId}))
  }

  const currentAlumni = alumni.currentAlumni && alumni.currentAlumni.user;

  return (
    <div style={{ margin: "0 130px" }}>
      {alumni.getAlumniStatus == STATUS.SUCCESS &&
      institute.getStatus == STATUS.SUCCESS &&
      currentAlumni ? (
        <>
          <div style={{ position: "relative" }}>
            <div style={{ height: "110px" }} className="profileContainer"></div>
            <Card style={{ paddingTop: "90px" }}>
              <Avatar icon={<UserOutlined style={{background:'#F2F3F4'}} />}
                style={{ border:'1px solid #8E44AD', position: "absolute", top: -90, background: currentAlumni.imageUrl ? 'white' : "#F2F3F4", cursor:'pointer' }}
                size={180}
                src={currentAlumni.imageUrl ? <Image src={currentAlumni.imageUrl} /> : null}
              />
              <Row>
                <Col span={20}>
                  <Space size='large'>
                    <Text style={{ fontSize: "30px" }}>{currentAlumni.name}</Text>
                    {alumni.currentAlumni?.isVerified ? 
                      <Tag style={{fontSize:16, padding:5}} color={'green'} icon={<CheckOutlined />}>Verified</Tag> 
                      :
                      <Tag style={{fontSize:16, padding:5}} color={'red'} icon={<CloseOutlined />}>Unverified</Tag> 
                    }
                  </Space>
                  <br />
                  <br />
                  <div style={{display:'flex', alignItems:'center'}}>
                    <span>
                      {updateBatch ?
                        <Form form={form} key={updateBatch} layout='inline' onFinish={_updateBatch} style={{margin:0, padding:0}}>
                          <Form.Item name='batchId'>
                            <Select defaultValue={alumni.currentAlumni.batch?.id} placeholder='Select Batch' style={{width:'200px'}}>
                              
                              {institute.singleInstitute.batches?.length ? 
                                institute.singleInstitute.batches.map(btch => 
                                  <Select.Option key={btch.id} value={btch.id}>{btch.name}</Select.Option>
                                )
                                :
                                <Text>Add Batch</Text>
                              }
                            </Select>
                          </Form.Item>
                          <FormItem>
                            <Button loading={updateMemberStatus === STATUS.FETCHING} htmlType='submit'>Save</Button>
                          </FormItem>
                        </Form>
                        :alumni.currentAlumni.batch ?
                          <span
                            style={{ padding: "6px", borderRadius: "5px" }}
                            className="batch-label"
                          >
                            <Text
                              style={{
                                fontSize: "15px",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {alumni.currentAlumni.batch.name}
                            </Text>
                          </span>
                          :
                          <Text>Add Batch</Text>
                      }
                    </span>
                    <span style={{paddingLeft:'20px', cursor:'pointer'}} onClick={() =>  setUpdateBatch(!updateBatch)}>
                      {!updateBatch ? <EditOutlined style={{fontSize:'30px'}}/> : <CloseCircleTwoTone style={{fontSize:'20px'}} twoToneColor='#eb2f96'/>}
                    </span>
                  </div>
                  <br />
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  <EditTwoTone style={{fontSize:"40px"}}  onClick={() => setUpdateProfileDrawer(true)} cursor="pointer" />
                </Col>
              </Row>
            </Card>
          </div>
          <br />
          <PersonalInfo currentAlumni={currentAlumni} />
          <br/>
          <SocialMediaLinks currentAlumni={currentAlumni}/>
          <br/>
          <Address currentAlumni={currentAlumni} updateAddress={() => setUpdateAddressDrawer(true)}/>
          <br/>
          <AssignGroups
            groups={institute.singleInstitute.groups}
            currentAlumni={alumni.currentAlumni}
          />
          {/* <br/> */}
          {/* <EducationInfo currentAlumni={currentAlumni}/> */}

          <br />
          
          <Experience alumni={alumni} />
        </>
      ) : alumni.getalumniStatus === STATUS.FETCHING || institute.getStatus === STATUS.FETCHING || batches.getBatchesStatus == STATUS.FETCHING ? (
        <Card loading={alumni.getalumniStatus === STATUS.FETCHING || institute.getStatus === STATUS.FETCHING || batches.getBatchesStatus == STATUS.SUCCESS}></Card>
      ) : (
        <Empty />
      )}
      <br />

      {updateProfileDrawer && <UpdateProfile currentAlumni={alumni.currentAlumni} visible={updateProfileDrawer} closeDrawer={() => setUpdateProfileDrawer(false)}/>}
      {updateAddressDrawer && <UpdateAddress visible={updateAddressDrawer} currentAlumni={alumni.currentAlumni} closeDrawer={() => setUpdateAddressDrawer(false)}/>}
    
    </div>
  );
};

const SocialMediaLinks = ({currentAlumni}) => {
  return(
    <Card>
      <Title level={4}>Social Media</Title>
      <Space size='large' wrap>
        <Space align='center' style={{fontSize:16}}>
          <Text>Facebook :</Text>
          {currentAlumni.facebookLink ? 
            <Button size='large' onClick={() => window.open(currentAlumni.facebookLink, '_blank')} 
              style={{padding:0}} icon={<LinkOutlined />} type='link'
            >
              Link
            </Button> 
            : '-' 
          }
        </Space>
        <Space align='center' style={{fontSize:16}}>
          <Text>Twitter :</Text>
          {currentAlumni.twitterLink ? 
            <Button size='large' onClick={() => window.open(currentAlumni.twitterLink, '_blank')} 
              style={{padding:0}} icon={<LinkOutlined />} type='link'
            >
              Link
            </Button> 
            : '-' 
          }
        </Space>
        <Space align='center' style={{fontSize:16}}>
          <Text>LinkedIn :</Text>
          {currentAlumni.linkedInLink ? 
            <Button size='large' onClick={() => window.open(currentAlumni.linkedInLink, '_blank')} 
              style={{padding:0}} icon={<LinkOutlined />} type='link'
            >
              Link
            </Button> 
            : '-' 
          }
        </Space>
        <Space align='center' style={{fontSize:16}}>
          <Text>Instagram :</Text>
          {currentAlumni.instagramLink ? 
            <Button size='large' onClick={() => window.open(currentAlumni.instagramLink, '_blank')} 
              style={{padding:0}} icon={<LinkOutlined />} type='link'
            >
              Link
            </Button> 
            : '-' 
          }
        </Space>
      </Space>
    </Card>
  )
}

const AssignGroups = ({ groups, currentAlumni }) => {
  const [assingGroupVisible, setAssignGroupVisible] = useState(false);
  const dispatch = useDispatch()
  const deleteId = useRef()

  const {removeGroupStatus} = useSelector(state => ({
    removeGroupStatus:state.alumni.removeGroupStatus
  }))

  const _removeGroup = (e, id) => {
    e.preventDefault()
    deleteId.current = id
    dispatch(removeGroupAction({groupId:id, memberIds:[currentAlumni.id]}))
  }

  return (
    <Card>
      <Row>
        <Col span={22}>
          <Title level={4}>Groups</Title>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <PlusOutlined
            style={{ fontSize: "25px", cursor: "pointer" }}
            onClick={() => setAssignGroupVisible(true)}
          />
          <AssignStudentGroups
            assignedGroups={currentAlumni.groups.map((g) => g.group)}
            currentAlumni={currentAlumni}
            closeDrawer={() => setAssignGroupVisible(false)}
            groups={groups}
            visible={assingGroupVisible}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={22}>
          {currentAlumni.groups.length ? 
            currentAlumni.groups.map((grp) => {
              const loading = deleteId.current === grp.group.id && removeGroupStatus === STATUS.FETCHING
              return (
                  <Tooltip title="Remove Group" key={grp.id}>
                    <Tag
                      color="processing"
                      closable={!loading}
                      onClose={(e) => _removeGroup(e, grp.groupId)}
                      style={{ fontSize: "14px", padding: "4px" }}
                    >
                      {grp.group.name}&nbsp;
                      {loading ?  
                        <LoadingOutlined />
                        :
                        null
                      }
                    </Tag>
                  </Tooltip>
                )
            })
            :
            <Empty description='No Group Assigned'/>
          }
        </Col>
      </Row>
      <br />
    </Card>
  );
};


const PersonalInfo = ({ currentAlumni }) => {
  
  return (
    <Card>
      <Descriptions title={<Title level={4}>Personal Information</Title>} bordered>
        <Descriptions.Item label="Gender">
          {currentAlumni.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {currentAlumni.dob && moment(currentAlumni.dob).format('DD-MM-YYYY') || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Primary Contact">
          {currentAlumni.contact}
        </Descriptions.Item>
        <Descriptions.Item label="Secondary Contact">
          {currentAlumni.contactSecondary}
        </Descriptions.Item>
        <Descriptions.Item label="Primary E-mail">
          {currentAlumni.email || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Secondary E-mail">
          {currentAlumni.emailSecondary || "-"}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

const Address = ({ currentAlumni, updateAddress }) => {
  const dispatch = useDispatch()

  const {getStatesStatus, statesList} = useSelector(state => ({
    getStatesStatus:state.states.getStatesStatus,
    statesList:state.states.statesList
  }))

  useEffect(() => {
    if(getStatesStatus !== STATUS.SUCCESS)
      dispatch(getStatesAction())
    }, [getStatesStatus, dispatch])


  let local = currentAlumni.addresses?.length && _.findIndex(currentAlumni.addresses,d => d.type === 'correspondence') !== -1 ?
    _.find(currentAlumni.addresses,d => d.type === 'correspondence') : null

  let permanent = currentAlumni.addresses?.length && _.findIndex(currentAlumni.addresses,d => d.type === 'permanent') !== -1 ?
    _.find(currentAlumni.addresses,d => d.type === 'permanent') : null
    
    return (
    <Card loading={getStatesStatus === STATUS.FETCHING}> 
        <div style={{marginBottom:local ? 30 : 0}}>
          <Descriptions extra={[<EditOutlined style={{fontSize:'28px', cursor:'pointer'}} 
            onClick={updateAddress}/>]} 
            title={<Title level={4}>Correspondence Address</Title>} bordered
          >
            {local ? 
              <>
                <Descriptions.Item label="Address" span={4}>
                  {local.address || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  {local.stateId && _.findIndex(statesList,s => s.id === local.stateId) !== -1 ?  
                    _.find(statesList,s => s.id === local.stateId).name
                    : '-'
                  }
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  {local.city || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Pincode">
                  {local.pincode || '-'}
                </Descriptions.Item>
              </>
              :
              <Descriptions.Item span={4}>
                Not added
              </Descriptions.Item>
            }
          </Descriptions>
        </div>
        <br/>
        <div>
              <Descriptions title={<Title level={4}>Permanent Address</Title>} bordered>
                {permanent ?
                  <>
                    <Descriptions.Item label="Address" span={4}>
                      {permanent.address || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="State">
                      {permanent.stateId && _.findIndex(statesList,s => s.id === permanent.stateId) !== -1 ?  
                        _.find(statesList,s => s.id === permanent.stateId).name
                        : '-'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {permanent.city || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Pincode">
                      {permanent.pincode || '-'}
                    </Descriptions.Item>
                  </>
                :
                <Descriptions.Item span={4}>
                  Not added
                </Descriptions.Item>
              }
            </Descriptions>
        </div>
    </Card>
  );
};
