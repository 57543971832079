import { create } from "apisauce";
import apiMonitor from "./monitor";
import { BaseURL } from "../../BaseUrl";

export const URIS = {
  VERSION: "/app/version",
  LOGIN: "/auth/login",
  REFRESH: "/refresh",
  LOGOUT: "/logout",
  STATES: "/app/states",
  ME:'/auth/me',
  ADD_ALUMNI:'/member/create',
  GET_ALUMNI:'/member',
  SEARCH_ALUMNIS:'/member/search',
  ADD_GROUP:'group/create',
  
  ADD_INSTITUTE: '/institute/create',
  ADD_BATCH: "/batch/create",
  GET_BATCHES:'/institute',
  GET_INSTITUTES: '/institute/all',
  ADD_INSTITUTE_STAFF: '/staff/create',
  GET_SINGLE_INSTITUTE: '/institute',
  ADD_EDUCATION: '/member/education',
  ASSIGN_GROUPS:'/member/groups',
  INSTITUTE: '/institute',
  STAFF: '/staff',
  UPDATE_USER:'/user/update',
  GROUP:'/group',
  UPDATE_BATCH:'/batch',
  REMOVE_GROUP:'/group/remove-members',
  ADD_STUDENT_EXCEL:'/member/batch-members',
  ADD_ALUMNI_EXCEL:'/member/batch-members',
  UPDATE_MEMBER:'/member',
  ALUMNI_EXPERIENCE: '/member/experience',
  GET_ALUMNI_LIST:'/member/all',
  UPDATE_ADDRESS:'/user/update',
  GET_ALL_POSTS:'/post/all',
  VERIFY_MEMBER:'/member',
  ADD_ACHIEVEMENT:'/member/achievement',
  SEND_NOTIFICATIONS:'/notification',
  GET_NOTIFICATIONS:'/notification/institute',
  DELETE_ALUMNUS:'/member/undelete'
};

let api = create({
  baseURL:BaseURL,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
  timeout: 45000,
});

api.addMonitor(apiMonitor);
// setInterceptor(api);

export const setAuthorizationHeader = (access_token) =>
  api.setHeader("Authorization", "Bearer " + access_token);

export const removeAuthorizationHeader = (access_token) =>{
  delete api.headers["Authorization"]
}

export const getAuthorizationHeader = () => {
  return api
}


export  {api as apiClient}

//for swr fetcher
export const getFetcher = (url, params, config) =>
  api
    .get(url, params, config)
    .then((response) => {
      return response.data;
    })
    .catch((er) => {
      throw er;
    });
