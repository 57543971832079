import { Card, Table } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { Truncate } from '../../components/Truncate'
import { STATUS } from '../../Constants'
import { getNotificationsAction } from '../../redux/reducers/notifications'

export const ManageNotifications = () => {
    const dispatch = useDispatch()

    const {getNotificationsStatus, allNotifications} = useSelector(state => ({
        getNotificationsStatus:state.notifications.getNotificationsStatus,
        allNotifications:state.notifications.allNotifications
    }))

    useEffect(() => {
        dispatch(getNotificationsAction({}))
    }, [dispatch])

    const handlePageChange = (e) => {
        dispatch(getNotificationsAction({page:e.current }))
    }
    
    return(
        <div>
            <CommonPageHeader title='Manage Notifications' />
            <br/>
            <Card>
                <div>
                    <Table loading={getNotificationsStatus === STATUS.FETCHING} onChange={handlePageChange} dataSource={allNotifications?.data || []} bordered 
                        pagination={{current:allNotifications?.page, showSizeChanger:false, total:allNotifications?.totalCount, pageSize:allNotifications?.limit}}
                    >
                        <Table.Column width={200} title='Sent On' dataIndex='createdAt' 
                            render={d => moment(d).format('ll')}
                        ></Table.Column>
                        <Table.Column title='Message' dataIndex='body' 
                            render={d => <Truncate>{d}</Truncate>}
                        ></Table.Column>
                        <Table.Column title='Message Type' 
                            render={d => d.pushToSms && d.pushToApp ? 'Notification + SMS' :d.pushToApp ? 'Notification' :d.pushToEmail ? 'Email' :d.pushToSms ? 'SMS' : null }
                        ></Table.Column>
                        <Table.Column title='Sent Count' dataIndex='sentCount'  ></Table.Column>
                        {/* <Table.Column title='Users' dataIndex=''></Table.Column> */}
                        {/* <Table.Column title='Status' ></Table.Column> */}
                    </Table>
                </div>
                <br/><br/>
            </Card>
        </div>
    )
}