import { DeleteOutlined, EditOutlined, PlusOutlined, TeamOutlined, UsergroupDeleteOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, Descriptions, Empty, List, Modal, Row, Space, Table, Tabs, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AddGroup } from '../../components/AddGroup'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { STATUS } from '../../Constants'
import { deleteGroupAction, getSingleInstituteAction } from '../../redux/reducers/instituteStaff'
import _ from 'lodash'
import { assignGroupsAction, getAlumniListAction, getGroupAlumniAction, removeGroupAction } from '../../redux/reducers/alumni'
import moment from 'moment'
import { AlumniModal } from '../ManageAlumni/AlumniModal'

export const ManageGroups = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const { instituteId, institute, addGroupStatus } = useSelector((state) => ({
        instituteId:state.user.user.staff.institute.id,
        institute:state.instituteStaff,
        addGroupStatus:state.instituteStaff.addGroupStatus
    }))

    const [addGroupDrawerVisible, setAddGroupDrawerVisible] = useState(false)
    const [selectedGroup, selectGroup] = useState('')

    useEffect(() => {
        if(institute.deleteGroupStatus == STATUS.SUCCESS)
            history.push('/manage-groups')    
    }, [institute.deleteGroupStatus])

    useEffect(() => {
        if(addGroupStatus === STATUS.SUCCESS)
            setAddGroupDrawerVisible(false)
    }, [addGroupStatus])

    useEffect(() => {
        if(params.id)
            selectGroup(_.find(institute.singleInstitute?.groups,grp => grp.id == params.id))
        else
            selectGroup(null)
    },[params.id, institute.getStatus, institute.updateGroupStatus])

    // useEffect(() => {
    //     dispatch(getSingleInstituteAction({id:instituteId}))
    // }, [])

    return(
        <>
            <CommonPageHeader
                title='Manage Groups'
                extra={[<Button onClick={() => setAddGroupDrawerVisible(true)} size='large' icon={<PlusOutlined />}> Add Group</Button>]}
            />
            
            {addGroupDrawerVisible ? <AddGroup visible={addGroupDrawerVisible} closeDrawer={() => setAddGroupDrawerVisible(false)}/> : null }
            <br/>
            <Card loading={institute.getStatus === STATUS.FETCHING}>
                {institute.getStatus === STATUS.SUCCESS && institute.singleInstitute.groups.length ?
                    <Row>
                        <Col span={6}> 
                            <ListGroups selectedGroup={selectedGroup} groups={institute.singleInstitute.groups}/>
                        </Col>
                        <Col span={18} style={{padding:'0 20px'}}>
                            {selectedGroup ? 
                                <GroupInfo institute={institute} group={selectedGroup}/>
                                : null
                            }
                        </Col>
                    </Row>
                    :
                    <Empty description='No Groups' />
                }
            </Card>
        </>
    )
}

const GroupInfo = ({group, institute}) => {
    const dispatch = useDispatch()

    const {updateGroupStatus} = useSelector(state => ({
        updateGroupStatus:state.instituteStaff.updateGroupStatus
    }))

    const [modal, contextHolder] = Modal.useModal()
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if(updateGroupStatus === STATUS.SUCCESS)
            setVisible(false)
    }, [updateGroupStatus])

    const config = {
        title:'Are you Sure ?',
        onOk:() => deleteGroup()
    }

    const deleteGroup = () => {
        dispatch(deleteGroupAction({id:group.id}))
    }

    const _updateGroup = () => {
        setVisible(true)
    }

    return(
        <>
            <Tabs defaultActiveKey="1" type='card'>
                <Tabs.TabPane tab="About" key="1">
                    <Descriptions title='Group Info' bordered
                        extra={[
                            <Button onClick={_updateGroup} style={{marginRight:'10px'}} icon={<EditOutlined />}>
                                Update
                            </Button>,
                            <Button onClick={() => modal.confirm(config)} loading={institute.deleteGroupStatus === STATUS.FETCHING} danger icon={<DeleteOutlined />}>
                                Delete
                            </Button>
                        ]}
                    >
                        <Descriptions.Item span={2} style={{minWidth:150}}  label='Name'>{group.name}</Descriptions.Item>
                        <Descriptions.Item span={2} label='Description'>{group.description || '-'}</Descriptions.Item>
                    </Descriptions>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Members" key="2">
                    <GroupMembers/>
                </Tabs.TabPane>
            </Tabs>
            
            {contextHolder}

            {visible ? <AddGroup visible={visible} group={group} closeDrawer={() => setVisible(false)}/> : null }
        </>
    )
}

const GroupMembers = () => {
    const dispatch = useDispatch()
    const params = useParams()

    const {getAlumniListStatus, groupMembers, assignGroupsStatus, removeGroupStatus} = useSelector(state => ({
        getAlumniListStatus:state.alumni.getAlumniListStatus,
        groupMembers:state.alumni.groupMembers,
        assignGroupsStatus:state.alumni.assignGroupsStatus,
        removeGroupStatus:state.alumni.removeGroupStatus
    }))

    const [openModal, setOpenModal] = useState()
    
    useEffect(() => {
        dispatch(getGroupAlumniAction({groupIds:[params.id]}))
    }, [params.id, dispatch])

    useEffect(() => {
        if(assignGroupsStatus === STATUS.SUCCESS)
            dispatch(getGroupAlumniAction({groupIds:[params.id]}))
    }, [assignGroupsStatus, dispatch])

    const handleOpenModal = () => {
        setOpenModal(!openModal)
    }

    const handlePageChange = (e) => {
        dispatch(getGroupAlumniAction({groupIds:[params.id], page:e.current}))
    }

    const handleSubmit = (selected) => {
        let data = {groupIds:[params.id], memberIds:selected.map(d => d.id)}
        dispatch(assignGroupsAction(data))
    }

    const handleRemove = (id) => {
        dispatch(removeGroupAction({groupId:params.id, memberIds:[id]}))

    }

    return(
        <div>
            <div style={{textAlign:'right'}}>
                <Button onClick={handleOpenModal}>Add Members</Button>
            </div>
            <br/>
            <Table dataSource={groupMembers?.data || []} loading={getAlumniListStatus === STATUS.FETCHING || removeGroupStatus === STATUS.FETCHING}
                pagination={{
                    total:groupMembers?.totalCount, 
                    defaultPageSize:parseInt(groupMembers?.limit) || 10, 
                    current:groupMembers ? parseInt(groupMembers.page) : 1, 
                    showSizeChanger:false
                }} 
                onChange={handlePageChange}
            >
                <Table.Column title='Image' dataIndex='user'
                    render={d => <Avatar  icon={<UserOutlined />} src={d.imageUrl}/>}
                />
                <Table.Column title='Name' dataIndex='user'
                    render={d => d.name}
                />
                <Table.Column title='Batch'
                    render={d => d.batch ? d.batch.startyear ? `${d.batch.name} (${d.batch.startyear} - ${d.batch.endyear || ''})` : d.batch.name : null}
                />
                <Table.Column title='DOB' dataIndex='user'
                    render={d => moment(d.dob).format('ll')}
                />
                <Table.Column title='Contact' dataIndex='user'
                    render={d => _.join(_.compact([d.contact, d.contactSecondary]), ', ')}
                />
                <Table.Column title='Email' dataIndex='user'
                    render={d => _.join(_.compact([d.email, d.emailSecondary]), ', ')}
                />
                <Table.Column title='Remove'
                    render={d => 
                        <Tooltip title='Remove'>
                            <Button size='small' onClick={() => handleRemove(d.id)} icon={<DeleteOutlined/>}/>
                        </Tooltip>
                    }
                />
            </Table>
            <br/><br/>
            {openModal ? 
                <AlumniModal visible={openModal} closeModal={handleOpenModal} 
                    defaultAlumnis={groupMembers?.data?.length ? groupMembers?.data.map(d => ({...d, alreadyAdded:true}))  : []} 
                    selectedAlumnis={handleSubmit}
                /> 
                : null
            }
        </div>
    )
}

const ListGroups = ({groups, selectedGroup}) => {
    const history = useHistory()

    return(
        <>
            <div style={{maxHeight:'500px', overflow:'auto'}}>
                <List
                    header={<Title level={4}>Groups</Title>}
                    bordered
                    itemLayout="horizontal"
                    dataSource={groups}
                    renderItem={item => {
                        const selected = selectedGroup &&  item.id == selectedGroup.id
                        return(
                            <List.Item style={{cursor:'pointer'}} onClick={() => history.push('/manage-groups/'+item.id)} className={selected && 'listItemBg'}>
                                <List.Item.Meta
                                    avatar={<TeamOutlined  style={{fontSize:'25px'}}/>}
                                    title={<Text className={selected && 'listItemBg'}>{item.name}</Text>}
                                    // description={item.description}
                                />
                            </List.Item>
                            
                        )
                    }}
                />
            </div>
            <br/><br/>
        </>
    )
}