import XLSX from 'xlsx'

export const isPDF = (mime) => {
  return mime === "application/pdf";
};

export const createBlob = async (base64Data) => {
  let blobfile = await fetch(`${base64Data}`);
  blobfile = await blobfile.blob();
  return blobfile;
};

export const sheetToJSON = (event, onSave, file) => {

  var selectedFile = !event ? file : event.target.files[0]
  let jsonObject
  if(selectedFile){
    let finaldata =[]

      var fileReader = new FileReader()
      fileReader.onload = (e) => {
          var data = e.target.result
          var workbook = XLSX.read(data, {type:'binary'})
        
          workbook.SheetNames.forEach(sheet => {
              let rowObject = XLSX.utils.sheet_to_row_object_array(
                  workbook.Sheets[sheet], {raw: false}
              )
              onSave(rowObject)
          })
      }
      fileReader.readAsBinaryString(selectedFile)

    }
}
