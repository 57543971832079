import { Modal, Form, Select, Table, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Truncate } from '../../components/Truncate'
import _ from 'lodash'
import Text from 'antd/lib/typography/Text'

export const SelectGroupModal = ({visible, closeModal, selectedGroups, defaultGroups}) => {

    const [selectedGroupsList, setGroups] = useState([])
    
    const {allGroups} = useSelector(state => ({
        allGroups:state.instituteStaff.singleInstitute.groups
    })) 

    useEffect(() => {
        if(defaultGroups)
            setGroups(defaultGroups)
    }, [defaultGroups])

    const handleSelect = (d) => {
        let data = _.xorBy(selectedGroupsList, [d], 'id')
        setGroups(data)
    }

    const handleSubmit = () => {
        selectedGroups(selectedGroupsList)
        closeModal()
    }

    return(
        <Modal visible={visible} width='60%' title='Groups' okText='Done' onOk={handleSubmit} onCancel={closeModal} >
            <Text>Selected Count: <Text style={{color:'#3498DB', fontWeight:'bold'}}>{selectedGroupsList.length}</Text></Text>
            <br/><br/>
            <Table size='small' bordered pagination={false} dataSource={allGroups || []}>
                <Table.Column title='Group Name' width={200} dataIndex='name'></Table.Column>
                <Table.Column title='Description' dataIndex='description'
                    render={d => <Truncate>{d}</Truncate>}
                ></Table.Column>
                <Table.Column width={110} title='Select'
                    render={d => {
                        const selected = _.findIndex(selectedGroupsList,g => g.id === d.id) != -1 
                        return(
                            selected ? 
                            <Button size='small' danger onClick={() => handleSelect(d)}>Remove</Button>
                            :
                            <Button size='small' onClick={() => handleSelect(d)}>Select</Button>
                        )
                    }
                    }
                ></Table.Column>
            </Table>
        </Modal>
    )
}