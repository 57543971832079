import { Button, Drawer, Form, Select } from "antd"
import FormItem from "antd/lib/form/FormItem"
import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { STATUS } from "../../Constants"
import { updateAlumniBatchAction, updateMemberAction, UpdateUserAction } from "../../redux/reducers/alumni"
import { updateBatchAction } from "../../redux/reducers/batches"
import { SelectBatchModal } from "../Notifications/SelectBatchModal"

export const UpdateStudentBatchModal = ({visible, closeModal, alumni}) => {
    const dispatch = useDispatch()

    const {allBatches, updateBatchStatus} = useSelector(state => ({
        allBatches:state.instituteStaff.singleInstitute.batches || [],
        updateBatchStatus:state.alumni.updateBatchStatus
    }))

    const [selectedBatch, setBatch] = useState()

    const handleSubmit = (e) => {
        dispatch(updateAlumniBatchAction({id:alumni.id, batchId:selectedBatch}))
    }

    const handleSelect = (e) => {
        
        setBatch(e)
    }

    return(
        <Drawer width='40%' title='Alumnus Batch' visible={visible} onClose={closeModal}>
            <Form layout='vertical' onFinish={handleSubmit}>
                <Form.Item label='Select Batch' name='batch'>
                    <Select placeholder='Select' value={selectedBatch} onChange={handleSelect}>
                        {allBatches.length ? 
                            allBatches.map(d => 
                                <Select.Option value={d.id} key={d.id}>
                                    {d.name} ({d.startyear} - {d.endyear})
                                </Select.Option>
                            )
                            :
                            null
                        }
                    </Select>
                    <br/><br/>
                    <FormItem>
                        <Button loading={updateBatchStatus === STATUS.FETCHING} htmlType='submit'>Submit</Button>
                    </FormItem>
                </Form.Item>
            </Form>
        </Drawer>
    )
}