import { Avatar, Card, Cascader, Descriptions, Input, List, Select, Space, Tag, Tooltip } from 'antd'
import Search from 'antd/lib/input/Search'
import { Option } from 'antd/lib/mentions'
import React, { useState } from 'react'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { searchAlumnisAction } from '../../redux/reducers/alumni'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../../Constants'
import { CheckCircleOutlined, CheckCircleTwoTone, CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'

export const SearchAlumni = () => {
    const dispatch = useDispatch()
    const [searchKey, setSearchKey] = useState('name')
   
    const {alumni} = useSelector((state) => ({
        alumni:state.alumni
    }))

    const searchTypes = [
        {id:1, key:'name', label:'Name'},
        {id:2, key:'contact', label:'Contact'}
    ]

    const searchAlumnis = (query) => {
        let data = {searchKey, searchQuery:query, limit:30, page:1}
        dispatch(searchAlumnisAction(data))
    }
    

    return(
        <div>
            <CommonPageHeader title='Search Alumni'/>
            <br/>
            <Card>
                <Input.Group compact>
                    <Select style={{ width: '20%' }} onChange={e => setSearchKey(e)} size='large' defaultValue="name">
                        {searchTypes.map(type => 
                            <Option value={type.key} key={type.id}>{type.label}</Option>
                        )}
                    </Select>
                    <Search style={{ width: '80%' }} autoFocus size='large' loading={alumni.searchAlmnisStatus == STATUS.FETCHING} 
                        placeholder={`Enter ${_.find(searchTypes,d => d.key == searchKey).label}`} allowClear 
                        enterButton="Search"
                        onSearch={e => e && searchAlumnis(e)}
                    />
                </Input.Group>
                <br/><br/>
                <AlumniList alumnisList={alumni.alumnisList}/>
                <br/><br/>
            </Card>
        </div>
    )
}

const AlumniList = ({alumnisList}) => {
    const history = useHistory()

    return(
        <List
            itemLayout="horizontal"
            dataSource={alumnisList}
            renderItem={item => (
            <List.Item actions={[<a key='view' onClick={() => history.push('/alumni/profile/'+item.id)}>View Profile</a>]}>
                <List.Item.Meta
                    avatar={<Avatar size='large' src={item.user.imageUrl} icon={<UserOutlined />} />}
                    title={<Space style={{fontWeight:'bold', fontSize:'16px'}}>
                        <Text>{item.user.name}</Text>
                        {item.isVerified ? 
                            <Tooltip title='Verified'>
                                <CheckCircleTwoTone style={{fontSize:18}} twoToneColor="#52c41a" />
                            </Tooltip>
                            :
                            null
                        }
                    </Space>}
                    description={
                        item &&
                        <Descriptions>
                            <Descriptions.Item label="Batch">
                                {item.batch ?
                                    <Space>
                                        <Text>{item.batch.name}</Text>
                                        {item.batch.startyear ? 
                                            <Text>{`(${item.batch.startyear} - ${item.batch.endyear || ''})`}</Text>
                                            :
                                            null
                                        }
                                    </Space>
                                    :
                                    '-'
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact">
                                {item.user.contact}
                            </Descriptions.Item>
                            <Descriptions.Item label="Date of Birth">
                                {item.user.dob}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                />
            </List.Item>
            )}
        />
    )
}