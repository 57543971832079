import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react'
import XLSX from 'xlsx';

export const ExportExcel = ({filename, button, data}) => {

    const  exportFile = () => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "report");
		XLSX.writeFile(wb, filename ? `${filename}.xlsx` : 'Report.xlsx')
	};

    return(
        <div>
            {button || <Button icon={<DownloadOutlined />} onClick={exportFile}>Export Excel</Button>}
        </div>
    )
}