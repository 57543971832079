import { Header } from "antd/lib/layout/layout";
import { useAppContext } from "../App/Context";
import {
    LogoutOutlined,
    UserOutlined,
  } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../Constants/Routes";
import { Avatar, Space, Tooltip } from "antd";
import { useSelector } from "react-redux";
import Text from "antd/lib/typography/Text";
  
export const DashboardHeader = (props) => {
    const history = useHistory()
    const { logout } = useAppContext();

    const user = useSelector(state => state.user.user)
  
    const openProfile = () => {
      history.push(ROUTES.MY_PROFILE)
    }
    return (
      <Header
        className="site-layout-background"
        style={{
          background:'white',
          padding: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div style={{ display: "flex", alignItems:'center' }}>
          <div className="nav-item" onClick={openProfile}>
            <Tooltip title='User Profile'>
            <Space>
              <Avatar style={{border:'1px solid #D6DBDF'}} src={user.imageUrl} />
              <Text>{user.name}</Text>
            </Space>
            </Tooltip>
            {/* <UserOutlined style={{ fontSize: "20px", color:'#283747' }} /> */}
          </div>
          <div className="nav-item" style={{marginRight:10}} onClick={logout}>
            <Tooltip title='Logout'>
              <LogoutOutlined style={{ fontSize: "20px", color:'#5D6D7E' }} />
            </Tooltip>
          </div>
        </div>
      </Header>
    );
  };
  