import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { STATUS } from "../../../Constants"
import { SuccessMessage } from "../../../Constants/CommonAlerts"
import { apis } from "../../../services/api/apis"
// import { requestUserApi } from "../../services/api/UserApis"

export const requestUserProfileAction = createAsyncThunk(
  "user/me",
  async (payload, thunkAPI) => {
    const response = await apis.requestUserApi()
    const { ok, problem, data } = response
    if (ok) {
      return data
    } else {
     return thunkAPI.rejectWithValue(problem)
    }
  }
)

export const updateCurrentUserAction = createAsyncThunk(
  'current-user/update',
  async(payload, thunkAPI) => {
      const response = await apis.updateUserApi(payload)
      const {ok, problem, data} = response

      console.log('payload', payload)
      if(payload.admin && ok)
          thunkAPI.dispatch(updateUserAction(data))
      
      if(ok)
          return data
      else 
      return thunkAPI.rejectWithValue(problem)

  }
)

const initialState = {}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActiveStaff: (state, action) => {
      state.activeStaff = action.payload
      state.user = action.payload?.user
    },
  },
  extraReducers: {
    [requestUserProfileAction.pending]: (state, action) => {
      state.status = STATUS.FETCHING
    },

    [requestUserProfileAction.rejected]: (state, action) => {
      state.status = STATUS.FAILED
    },

    [requestUserProfileAction.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCESS
      state.user = action.payload
    },

    [updateCurrentUserAction.pending]: (state, action) => {
      state.updateUserStatus = STATUS.FETCHING
    },

    [updateCurrentUserAction.rejected]: (state, action) => {
      state.updateUserStatus = STATUS.FAILED
    },

    [updateCurrentUserAction.fulfilled]: (state, action) => {
      state.updateUserStatus = STATUS.SUCCESS
      state.user = {...action.payload, staff:state.user.staff}
      SuccessMessage('Profile Updated')
    },
  },
})

export const { setActiveStaff, updateUserAction } = userSlice.actions
export const userReducer = userSlice.reducer