import { Button, Card, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import { UploadImageBox } from '../../components/UploadImageBox'
import { STATUS } from '../../Constants'
import { UpdateUserAction } from '../../redux/reducers/alumni'
import { updateCurrentUserAction } from '../../redux/reducers/user'

export const MyProfile = () => {
    const dispatch = useDispatch()

    const {user, updateUserStatus} = useSelector(state => ({
        user:state.user.user,
        updateUserStatus:state.user.updateUserStatus
    }))

    const [userData, changeUserData] = useState({})

    useEffect(() => {
        if(user){
            let {name, contact, email, username, imageUrl} = user
            let data = {name, contact, email, username, imageUrl}
            changeUserData(data)
        }

    }, [user])

    const handleAvatar = (e) => {
        changeUserData(d => ({...d, imageUrl:e?.file?.response?.url}))
    }

    const handleChange = (e) => {
        changeUserData(d => ({...d, [e.target.id]:e.target.value}))
    }

    const handleSubmit = () => {
        dispatch(updateCurrentUserAction({...userData, id:user.id}))
    }

    const {name, contact, email, username, imageUrl} = userData
    const disabled = !name || !contact || !email || !username
    
    console.log('user', imageUrl, userData)
    return(
        <div>
            <CommonPageHeader
                title='User Profile'
            />
            <br/>
            <Card>
                <Form onFinish={handleSubmit} key={userData} labelCol={{span:4}} wrapperCol={{span:12}}>
                    <Form.Item label='User Image'>
                        <UploadImageBox key={userData.imageUrl} defaultImg={imageUrl} ratio={'1:1'} 
                            onRemove={handleAvatar} getImage={handleAvatar}    
                        />
                    </Form.Item>
                    <Form.Item label='Name' required>
                        <Input placeholder='Name' id='name' onChange={handleChange} value={name}/>
                    </Form.Item>
                    <Form.Item label='Contact' required>
                        <Input placeholder='Contact' id='contact' onChange={handleChange} value={contact} />
                    </Form.Item>
                    <Form.Item label='Email' required>
                        <Input placeholder='Email' id='email' onChange={handleChange} value={email} />
                    </Form.Item>
                    <Form.Item label='Username' required>
                        <Input placeholder='Username' id='username' onChange={handleChange} value={username} />
                    </Form.Item>
                    <Form.Item wrapperCol={{offset:4, span:12}}>
                        <Button loading={updateUserStatus === STATUS.FETCHING} type='primary' disabled={disabled} htmlType='submit' >Update</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}