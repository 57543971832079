import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tooltip, Divider } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { FcGraduationCap, FcManager } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { AddAlumniEducationModal } from "./AddAlumniEducationModal";
import { AddAlumniExperienceModal } from "./AddAlumniExperienceModal";
import _ from 'lodash'
import { AiOutlineEdit } from "react-icons/ai"
import moment from "moment";
import { STATUS } from "../../Constants";
import { AddAchievementModal } from "./AddAchievementModal";

export const Experience = ({alumni}) => {
    const dispatch = useDispatch();
    const {editEducationStatus, addEducationStatus, deleteEducationStatus, editExperienceStatus , deleteExperienceStatus, addExperienceStatus} = 
      useSelector(state => ({
        editEducationStatus:state.alumni.editEducationStatus,
        addEducationStatus:state.alumni.addEducationStatus,
        deleteEducationStatus:state.alumni.deleteAlumniEducationStatus,
        editExperienceStatus:state.alumni.editExperienceStatus,
        deleteExperienceStatus:state.alumni.deleteAlumniExperienceStatus,
        addExperienceStatus:state.alumni.addExperienceStatus
      })) 

    const [educationModal, changeEducationModal] = useState({modal: false, data: ""});
    const [experienceModal, changeExperienceModal] = useState({modal: false, data: ""});
  
    useEffect(() => {
      if(editEducationStatus === STATUS.SUCCESS || editExperienceStatus  === STATUS.SUCCESS || addEducationStatus  === STATUS.SUCCESS || 
        addExperienceStatus === STATUS.SUCCESS || deleteEducationStatus === STATUS.SUCCESS || deleteExperienceStatus  === STATUS.SUCCESS
      ){
        changeEducationModal(false)
        changeExperienceModal(false)
      }
    }, [editEducationStatus, editExperienceStatus, addEducationStatus, deleteEducationStatus, deleteExperienceStatus, addExperienceStatus])

    return(
      <Card>
        <Row>
          <Col span={22}>
            <Title level={4}>Education</Title>
            {alumni.currentAlumni && alumni.currentAlumni.educations
              ? _.map(alumni.currentAlumni.educations, (s) => (
                  <div
                    key={s.id}
                    style={{
                      display: "flex",
                      alignItems: "start",
                      margin: "20px 0",
                    }}
                  >
                    <div style={{ paddingRight: "30px" }}>
                      <FcGraduationCap fontSize="50px" />
                    </div>
                    <div style={{ display: "flex", flex:1 }}>
                      <div>
                        <Title level={5} style={{ margin: 0, padding: 0 }}>
                          <b>{s.school}</b>
                        </Title>
                        <div>
                          <Text type='secondary'>{s.degree || ''}</Text>
                        </div>
                        <div>
                          <Text type="secondary">{s.studyField}</Text>
                        </div>
                        {s.startYear ?
                          <div>
                            <Text type="secondary">
                              {moment(s.startYear).format('MMM YYYY') + " - " + moment(String(s.endYear)).format('MMM YYYY')}
                            </Text>
                          </div>
                          : null
                        }
                      </div>
                      <div style={{ paddingLeft: "30px" }}>
                        <Tooltip placement="top" title='Update Education'>
                          <EditOutlined
                            style={{fontSize:"24px"}}
                            cursor="pointer"
                            onClick={() =>
                              changeEducationModal({ modal: true, data: s })
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </Col>
          <Col span={2} style={{ textAlign: "right" }}>
            <Tooltip placement="top" title='Add Education'>
              <PlusOutlined
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() =>
                  changeEducationModal({ modal: true, data: "" })
                }
              />
            </Tooltip>
          </Col>
        </Row>

        <Divider/>
        
        <Row>
          <Col span={22}>
            <Title level={4}>Experience</Title>
            {alumni.currentAlumni && alumni.currentAlumni.experiences
              ? _.map(alumni.currentAlumni.experiences, (s) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  margin: "20px 0",
                }}
              >
                <div style={{ paddingRight: "30px" }}>
                  <FcManager style={{ fontSize: "50px" }} />
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <Title level={5} style={{ margin: 0, padding: 0 }}>
                      <b>{s.title}</b>
                    </Title>
                    <Text>{s.company}</Text>
                    <div>
                      <Text>{s.description}</Text>
                    </div>
                    <div>
                      <Text>{s.location}</Text>
                    </div>
                    {s.startDate ? 
                      <div>
                        <Text type="secondary">
                          {s.current ? 
                            (moment(s.startDate).format('LL') +' - Currently Working') 
                            : 
                            (moment(s.startDate).format('LL') + (s.endDate ? ' - ' + moment(s.endDate).format('LL') : '') 
                          )}
                        </Text>
                      </div> 
                      : null
                    }
                    <div>
                      <Text type="secondary">{s.employmentType}</Text>
                    </div>
                  </div>
                  <div style={{paddingLeft:10}}>
                    <Tooltip placement="top" title='Update Experience'>
                    <AiOutlineEdit
                      fontSize="24px"
                      cursor="pointer"
                      onClick={() =>
                        changeExperienceModal({ modal: true, data: s })
                      }
                    />
                  </Tooltip>
                  </div>
                </div>
              </div>
            ))
            : null}
          </Col>
           <Col span={2} style={{ textAlign: "right" }}>
            <Tooltip placement="top" title='Add Experience'>
              <PlusOutlined
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() =>
                  changeExperienceModal({ modal: true, data: "" })
                }
              />
            </Tooltip>
          </Col>
        </Row>

        <Divider/>
        
        <Achievements alumni={alumni.currentAlumni}/>

        {educationModal.modal ? (
          <AddAlumniEducationModal
            educationModal={educationModal.modal}
            preSelected={educationModal.data}
            closeModal={() =>
              changeEducationModal({ modal: false, data: "" })
            }
            alumni={alumni}
          />
        ) : null}
        {experienceModal.modal ? (
          <AddAlumniExperienceModal
            experienceModal={experienceModal.modal}
            preSelected={experienceModal.data}
            closeModal={() =>
              changeExperienceModal({ modal: false, data: "" })
            }
            alumni={alumni}
          />
        ) : null}
        <br />
      </Card>
    )
  }

  const Achievements = ({alumni}) => {

    const {addAchievementStatus, updateAchievementStatus, deleteAchievementStatus} = useSelector(state => ({
      addAchievementStatus:state.alumni.addAchievementStatus,
      updateAchievementStatus:state.alumni.updateAchievementStatus,
      deleteAchievementStatus:state.alumni.deleteAchievementStatus
    }))

    const [achievementModal, openAchievementModal] = useState()
    const [updateAchievementModal, openUpdateAchievementModal] = useState()

    useEffect(() => {
      if(addAchievementStatus === STATUS.SUCCESS || updateAchievementStatus === STATUS.SUCCESS || deleteAchievementStatus === STATUS.SUCCESS){
        openAchievementModal(false)
        openUpdateAchievementModal(false)
      }
    }, [addAchievementStatus, updateAchievementStatus, deleteAchievementStatus])

    const handleAchievementModal = () => {
      openAchievementModal(!achievementModal)
    }

    const handleUpdate = (d) => {
      openUpdateAchievementModal(updateAchievementModal ? false : d)
    }

    return(
      <div>
        <div style={{display:'flex', padding:'10px 0', justifyContent:'space-between'}}>
          <Title level={4}>Achievements</Title>
          <div>
            <Tooltip placement="top" title='Add Achievement'>
              <PlusOutlined
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={handleAchievementModal}
              />
            </Tooltip>
          </div>
        </div>

        {alumni && alumni.achievements
          ? _.map(alumni.achievements, (s) => (
              <div
                key={s.id}
                style={{display: "flex", alignItems: "start", margin: "20px 0",}}
              >
                <div style={{ paddingRight: "30px" }}>
                  <FcGraduationCap fontSize="50px" />
                </div>
                <div style={{ display: "flex", flex:1 }}>
                  <div>
                    <Title level={5} style={{ margin: 0, padding: 0 }}>
                      <b>{s.title}</b>
                    </Title>
                    <div>
                      <Text type='secondary'>{s.description || ''}</Text>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "30px" }}>
                    <Tooltip placement="top" title='Update Achievement'>
                      <EditOutlined style={{fontSize:"24px"}} cursor="pointer" onClick={() => handleUpdate(s)} />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          : null}

        {achievementModal ? <AddAchievementModal alumni={alumni} visible={achievementModal} closeModal={handleAchievementModal}  /> : null}
        {updateAchievementModal ? <AddAchievementModal alumni={alumni} currentAchievement={updateAchievementModal} visible={updateAchievementModal} closeModal={handleUpdate}  /> : null}
        
      </div>
    )
  }