import { Card, Space, Statistic, List, Avatar, Button, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import _ from 'lodash'
import { useEffect } from "react";
import { getAlumniAction, getAlumniListAction } from "../../redux/reducers/alumni";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

export const MainDashboard = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    
    const {institute, alumniList} = useSelector(state => ({
        institute:state.instituteStaff.singleInstitute,
        alumniList:state.alumni.alumniList,
    }))

    useEffect(() => {
        dispatch(getAlumniListAction({searchKeys:['isVerified'], searchVals:[false]}))
    }, [dispatch])

    const handleLoadMore = () => {
        history.push('/alumnis/1')
    }

    let totalAlumnis = institute?.batches?.length ? _.sum(institute.batches.map(b => b.memberCount)) : 0
    let unverifiedAccount =  alumniList?.data.length ? 
        _.chain(alumniList.data).filter(d => d.user.name).take(5).value() 
        : []

    return (
        <Content>
            <CommonPageHeader title='Dashboard'/>
            <br/>
            <div style={{display:'flex'}}>
                <Card style={{flex:1, marginRight:10}}>
                    <Statistic valueStyle={{fontSize:40}} value={totalAlumnis} title="Total Alumni" />
                </Card>
                <Card style={{flex:3, marginLeft:10}}>
                    <Text type='secondary'>Newly Arrived</Text>
                    {unverifiedAccount.length ? 
                        <div>
                            <List size='small'
                                itemLayout="horizontal"
                                dataSource={unverifiedAccount}
                                renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                    avatar={<Avatar icon={<UserOutlined />} src={item.user.imageUrl} />}
                                    title={item.user.name}
                                    description={item.user.text_for_verification?.length > 200 ?  
                                        item.user.text_for_verification?.substring(0, 200)+'...'
                                        :
                                        item.user.text_for_verification
                                    }
                                    />
                                </List.Item>
                                )}
                            />
                            <br/>

                            {_.filter(alumniList.data, d => d.user.name).length > 5 ? 
                                <div style={{width:'100%', textAlign:'center'}}>
                                    <Button onClick={handleLoadMore} size='small' type='link'>View More</Button>
                                </div>
                                : null
                            }
                        </div>
                        :
                        <div>
                            <Text>No accounts available</Text>
                        </div>
                    }
                    {/* {unverifiedAccount.length ? 
                        unverifiedAccount.map(d => 
                            
                        )
                        :
                        <Text>No accounts available</Text>
                    } */}
                    
                </Card>
            </div>
            <br/>
            <div style={{display:'flex'}}>
                <Card style={{flex:1, marginRight:10}}>
                    <Text type='secondary'>All Batches and Their Members</Text>

                    <br/><br/>
                    <Table size="small" pagination={false} dataSource={institute?.batches?.length ? institute.batches : []}>
                        <Table.Column title='Batch' 
                            render={batch => batch.startyear ? `${batch.name} (${batch.startyear} - ${batch.endyear})` :batch.name}
                        />
                        <Table.Column title='Memmbers Count' dataIndex={'memberCount'}/>
                    </Table>
                    <br/><br/><br/>
                </Card>
                <Card style={{flex:1 ,marginLeft:10}}>
                    <Text type='secondary'>All Groups and Their Members</Text>
                    
                    {/* <Text style={{fontSize:20, fontWeight:'bold'}}>All Batches and Their Alumni</Text> */}
                    <br/><br/>
                    <Table size="small" pagination={false} dataSource={institute?.groups?.length ? institute.groups : []}>
                        <Table.Column title='Batch' dataIndex={'name'} />
                        <Table.Column title='Memmbers Count' dataIndex={'memberCount'}/>
                    </Table>
                    <br/><br/><br/>
                </Card>
            </div>
        </Content>
    );
  };