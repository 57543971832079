import { combineReducers } from "redux"
import { batchReducer } from "./batches"
import { userReducer } from "./user"
import { statesReducer } from "./states"
import { instituteReducer} from './institute'
import { instituteStaffReducer } from './instituteStaff'
import { alumniReducer } from "./alumni"
import { postReducer } from "./posts"
import { notificationReducer } from "./notifications"

const appReducer = combineReducers({
    user: userReducer,
    institute: instituteReducer,
    batches: batchReducer,
    states: statesReducer,
    instituteStaff: instituteStaffReducer,
    alumni: alumniReducer,
    posts:postReducer,
    notifications:notificationReducer,
})


export const reducer = (state, action) => {
    if (action.type === "CLEAR_LOGIN") {
      state = undefined
    }
    return appReducer(state, action)
  }