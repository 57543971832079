import {
  AppstoreOutlined,
  BankOutlined,
  BarChartOutlined,
  CloudOutlined,
  HomeOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  SendOutlined,
  ShopOutlined,
  SolutionOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { AiOutlineMessage, AiOutlineUnorderedList } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../Constants/Routes";

const sysadminMenu = [
  {
    key: 1,
    label: "Institute",
    icon: <BankOutlined />,
    items: [
      {
        key: ROUTES.INSTITUTE_ADD,
        label: "Add Institute",
        icon: <PlusCircleOutlined />,
      },
      {
        key: "/",
        label: "Institute List ",
        icon: <UnorderedListOutlined />,
      },
    ],
  },
];

const adminMenu = [
  { key: "/", label: "Home", icon: <HomeOutlined /> },
  {
    index: 5,
    key: ROUTES.MANAGE_BATCHES,
    label: "Manage Batches",
    icon: <SolutionOutlined />,
  },
  {
    index: 6,
    key: ROUTES.ADD_ALUMNI,
    label: "Add Alumni",
    icon: <UserAddOutlined />,
  },
  {
    index: 7,
    key: ROUTES.SEARCH_ALUMNI,
    label: "Search Alumni",
    icon: <SearchOutlined />,
  },
  { index: 8, key: ROUTES.ADD_GROUP, label: "Groups", icon: <TeamOutlined /> },
  { index: 9, key: ROUTES.ALUMNIS_LIST, label: "Alumni", icon: <UserSwitchOutlined /> },
  // { index: 10, key: ROUTES.MANAGE_POSTS, label: "Manage Posts", icon: <UserSwitchOutlined /> },
  { index: 11, label: "Notifications", icon: <MessageOutlined />,
    items:[
      {index:1, key: ROUTES.SEND_NOTIFICATIONS, label:'Send', icon:<SendOutlined />},
      {index:2, key: ROUTES.MANAGE_NOTIFICATIONS, label:'List', icon:<UnorderedListOutlined/>}
    ]
  },

];

export const SideMenu = (props) => {
  const user = useSelector((s) => s.user.user);
  const history = useHistory();
  const items = user.role === "SYSADMIN" ? sysadminMenu : adminMenu; //rolecheck
  return (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {
        history.push(key)
      }}
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["4"]}
    >
      {items.map((m) => {
        let key = m.key

        if (typeof key === "object") {
          key = key.rootPath || key.key
        }

        return m.items ? (
          <SubMenu key={key} icon={m.icon} title={m.label}>
            {m.items.map((sm) => {
              let key = m.key
              if (typeof key === "object") {
                key = key.rootPath || key.key
              }
              return (
                <Menu.Item key={sm.key} icon={sm.icon}>
                  {sm.label}
                </Menu.Item>
              );
            })}
          </SubMenu>
        ) : (
          <Menu.Item key={key} icon={m.icon}>
            {m.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
