
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
  
  import { useIsAuthenticated } from "../App/Context";
  import { LoginRoute } from "./Login";
  import {AppRoutes} from "./ProtectedApp";
  
  const AppRouter = (props) => {
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute path="/login" component={LoginRoute} />
          <ProtectedRoute path="/" component={AppRoutes} />
        </Switch>
      </BrowserRouter>
    );
  };
  
  const PublicRoute = (props) => {
    const auth = useIsAuthenticated()
    return !auth ? (
      <Route {...props}  />
    ) : (
      <Redirect to={"/"} />
    );
  };
  
  const ProtectedRoute = (props) => {
    const auth = useIsAuthenticated()  
    return auth ? (
      <Route {...props}  />
    ) : (
      <Redirect to={"/login"} />
    );
  };
  
  export default AppRouter;
  