import moment from "moment";
import {
  Tag,
  Tooltip,
  Avatar,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  Row,
  Form,
  Modal,
  Button,
  Input,
  DatePicker,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { FcGraduationCap, FcManager } from "react-icons/fc";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  getAlumniAction,
  addEducationAction,
  editEducationAction,
  resetEducationStatusAction,
  deleteAlumniEducationAction,
  resetEditAlumni,
} from "../../redux/reducers/alumni";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { STATUS } from "../../Constants";
import { getSingleInstituteAction } from "../../redux/reducers/instituteStaff";
import { AssignStudentGroups } from "../../components/AssignStudentGroups";

import _ from "lodash";
import { UpdateProfile } from "../../components/UpdateAlumniProfile";

const formItemLayout = {
  labelCol: {
    xs: { span: 22 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

const tailLayout = {
  wrapperCol: { offset:4, span: 22 },
};

export const AddAlumniEducationModal = (props) => {
  const dispatch = useDispatch();

  const {editEducationStatus, deleteAlumniEducationStatus, addEducationStatus} = useSelector(state => ({
    editEducationStatus:state.alumni.editEducationStatus,
    deleteAlumniEducationStatus:state.alumni.deleteAlumniEducationStatus,
    addEducationStatus:state.alumni.addEducationStatus

  }))

  let [formKey, setFromKey] = useState(1)
  const [stateData, stateChange] = useState({
    school: "",
    degree: "",
    studyField: "",
    memberId: props.alumni.currentAlumni.id,
    startYear: "",
    endYear: "",
  });

  const curretStateData = () => {
    stateChange({
      school: props.preSelected.school ? props.preSelected.school : "",
      degree: props.preSelected.degree ? props.preSelected.degree : "",
      studyField: props.preSelected.studyField
        ? props.preSelected.studyField
        : "",
      memberId: props.alumni.currentAlumni.id,
      startYear: props.preSelected.startYear
        ? moment(props.preSelected.startYear, "YYYY")
        : "",
      endYear: props.preSelected.endYear
        ? moment(props.preSelected.endYear, "YYYY")
        : "",
    });
  };

  useEffect(() => {
    if (props.preSelected) {
      curretStateData();
      setFromKey(formKey + 1) 
    }
  }, [props.preSelected]);

  // useEffect(() => {
  //   if (addEducationStatus === "SUCCESS" || deleteAlumniEducationStatus === 'SUCCESS' || editEducationStatus === 'SUCCESS') {
  //     stateChange({
  //       school: "",
  //       degree: "",
  //       studyField: "",
  //       memberId: props.alumni.currentAlumni.id,
  //       startYear: "",
  //       endYear: "",
  //     });
  //     dispatch(resetEducationStatusAction());
  //     props.closeModal();
  //   }
  // }, [addEducationStatus, deleteAlumniEducationStatus, editEducationStatus]);

  const onFinish = () => {
    let data ={
      school: stateData.school,
      degree: stateData.degree,
      studyField: stateData.studyField,
      startYear: stateData.startYear,
      endYear: stateData.endYear,
    }
    if (props.preSelected) {
      data = {...data, id: props.preSelected.id};
      dispatch(editEducationAction(data))
    } else {
      data = {...data}
      dispatch(addEducationAction({memberId: props.alumni.currentAlumni.id, ...data}));
    }
  };

  let selectFormData = (value, type) => {
    stateChange({ ...stateData, [type]: value });
  };

  const deleteAlumniEducation = (id) => {
    dispatch(deleteAlumniEducationAction({id}));
  }
  let dateFormat = (d) => {
    return d ? moment(d).format() : null
  }

  const selectDate = (date) => {
    if(date)
      stateChange(d => ({ ...d, startYear:dateFormat(date[0]), endYear:dateFormat(date[1]) }))
    else
      stateChange(d => ({ ...d, startDate:null, endDate:null }))
  }

  const rules = [{required:true, message:'Please fill in the field.'}]
  return (
    <Modal
      visible={props.educationModal}
      footer={null}
      width="50%"
      onOk={() => console.log("")}
      onCancel={() => props.closeModal()}
      key={formKey}
    >
      <div style={{ padding: "20px" }}>
        <Card title={
          <div>
            <span>{props.preSelected ? "Update Education" : "Add Education"}</span>
          </div>} 
        bordered={false} style={{ width: "100%" }}>
          <Form onFinish={onFinish} {...formItemLayout} layout="horizontal">
            <Form.Item name='school' label="School"
              rules={rules} initialValue={stateData.school} 
            >
              <Input
                placeholder="Input School Name"
                onChange={(e) => selectFormData(e.target.value, "school")}
                // defaultValue={stateData.school}
                // value={stateData.school}
              />
            </Form.Item>
            <Form.Item label="Degree" name='degree' initialValue={stateData.degree}>
              <Input
                placeholder="Input Degree"
                onChange={(e) => selectFormData(e.target.value, "degree")}
              />
            </Form.Item>
            <Form.Item label="Study Field" name='field' initialValue={stateData.studyField}>
              <Input
                placeholder="Input StudyField"
                onChange={(e) => selectFormData(e.target.value, "studyField")}
              />
            </Form.Item>
            <Form.Item required label="Start Date"
              rules={rules} name='date' initialValue={stateData.startYear ? [stateData.startYear, stateData.endYear] : null}
            >
              <DatePicker.RangePicker
                picker="month"
                format="YYYY-MM"
                onChange={selectDate}
              />
            </Form.Item>
            {/* <Form.Item label='End Date'>
              <DatePicker
                picker="month"
                format="mm"
                onChange={(e) => selectFormData(e, "endYear")}
                defaultValue={stateData.endYear}
                value={stateData.endYear}
              />
            </Form.Item> */}
            <Form.Item {...tailLayout}>
                <Button htmlType='submit' loading={addEducationStatus === STATUS.FETCHING || editEducationStatus === STATUS.FETCHING} type="primary">
                  {props.preSelected ? "Update" : "Add"}
                </Button>&nbsp;&nbsp;
                {props.preSelected ? 
                  <Button type='danger' loading={deleteAlumniEducationStatus === STATUS.FETCHING} onClick={() => deleteAlumniEducation(props.preSelected.id)}>
                    Delete
                  </Button>
                  : null
                }
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Modal>
  );
};
