import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { STATUS } from "../../../Constants"
import { SuccessMessage } from "../../../Constants/CommonAlerts"
import { apis } from "../../../services/api/apis"
import _ from 'lodash';
// import { requestUserApi } from "../../services/api/UserApis"

export const addInstituteStaffAction = createAsyncThunk(
  "institute/addinstituteStaff",
  async (payload, thunkAPI) => {
    const response = await apis.addInstituteStaffApi(payload)
    const { ok, problem, data } = response
    if (ok) {
      return data
    } else {
     return thunkAPI.rejectWithValue(problem)
    }
  }
)

export const getSingleInstituteAction = createAsyncThunk(
  "institute/getSingleinstitute",
  async (payload, thunkAPI) => {
    const response = await apis.getSingleInstituteApi(payload)
    const { ok, problem, data } = response
    if (ok) {
      return data
    } else {
     return thunkAPI.rejectWithValue(problem)
    }
  }
)

export const editInstituteStaffAction = createAsyncThunk(
  "institute/editInstituteStaff",
  async (payload, thunkAPI) => {
    const response = await apis.editInstituteStaffApi(payload)
    const { ok, problem, data } = response
    if (ok) {
      return data
    } else {
     return thunkAPI.rejectWithValue(problem)
    }
  }
)

export const deleteInstituteStaffAction = createAsyncThunk(
  "institute/deleteInstituteStaff",
  async (payload, thunkAPI) => {
    const response = await apis.deleteInstituteStaffApi(payload)
    const { ok, problem, data } = response
    if (ok) {
      return {data, payload}
    } else {
     return thunkAPI.rejectWithValue(problem)
    }
  }
)

export const addGroupAction = createAsyncThunk(
  'create/group',
  async(payload, thunkAPI) => {
      const response = await apis.addGroupApi(payload)
      const {ok, problem, data} = response

      if(ok)
          return data
      else
          return thunkAPI.rejectWithValue(problem)
  }
)

export const updateGroupAction = createAsyncThunk(
  'update/group',
  async(payload, thunkAPI) => {
      const response = await apis.updateGroupApi(payload)
      const {ok, problem, data} = response

      if(ok)
          return data
      else
          return thunkAPI.rejectWithValue(problem)
  }
)

export const deleteGroupAction = createAsyncThunk(
  'group/delete',
  async(payload, thunkAPI) => {
    const response = await apis.deleteGroupApi(payload)
    const {ok, problem, data} = response

    if(ok)
      return ({data, extraData:payload})
    else
      return thunkAPI.rejectWithValue(problem)
  }
)

const initialState = {
  addStatus:STATUS.NOT_STARTED
}

const instituteStaffSlice = createSlice({
  name: "instituteStaff",
  initialState,
  reducers: {
      resetEditStatusAction: (state, action) => {
        state.addStatus = STATUS.NOT_STARTED
      },
  },
  extraReducers: {
    [addInstituteStaffAction.pending]: (state, action) => {
      state.addStatus = STATUS.FETCHING
    },

    [addInstituteStaffAction.rejected]: (state, action) => {
      state.addStatus = STATUS.FAILED
    },

    [addInstituteStaffAction.fulfilled]: (state, action) => {
      state.addStatus = STATUS.SUCCESS
      state.added = action.payload
    },

    [editInstituteStaffAction.pending]: (state, action) => {
      state.addStatus = STATUS.FETCHING
    },

    [editInstituteStaffAction.rejected]: (state, action) => {
      state.addStatus = STATUS.FAILED
    },

    [editInstituteStaffAction.fulfilled]: (state, action) => {
      state.addStatus = STATUS.SUCCESS
      state.added = action.payload
    },

    [deleteInstituteStaffAction.pending]: (state, action) => {
      state.deleteStatus = STATUS.FETCHING
    },

    [deleteInstituteStaffAction.rejected]: (state, action) => {
      state.deleteStatus = STATUS.FAILED
    },

    [deleteInstituteStaffAction.fulfilled]: (state, action) => {
      state.deleteStatus = STATUS.SUCCESS
      state.singleInstitute = Object.assign({} ,state.singleInstitute, {staffs: _.filter(state.singleInstitute.staffs, s => s.id !== action.payload.payload.id)})
      SuccessMessage('Staff Deleted')
    },

    [getSingleInstituteAction.pending]: (state, action) => {
      state.getStatus = STATUS.FETCHING
    },

    [getSingleInstituteAction.rejected]: (state, action) => {
      state.getStatus = STATUS.FAILED
    },
    [getSingleInstituteAction.fulfilled]: (state, action) => {
      state.getStatus = STATUS.SUCCESS
      state.singleInstitute = action.payload
    },

    [addGroupAction.pending]: (state, action) => {
      state.addGroupStatus = STATUS.FETCHING
    },
    [addGroupAction.fulfilled]: (state, action) => {
      SuccessMessage()
      state.addGroupStatus = STATUS.SUCCESS
      state.singleInstitute.groups.unshift(action.payload)
    },
    [addGroupAction.rejected]: (state, action) => {
        state.addGroupStatus = STATUS.FAILED
    },

    [deleteGroupAction.pending]:(state,action) => {
      state.deleteGroupStatus = STATUS.FETCHING
    },
    [deleteGroupAction.fulfilled]:(state,action) => {
      SuccessMessage()
      _.remove(state.singleInstitute.groups,grp => grp.id == action.payload.extraData.id)
      state.deleteGroupStatus = STATUS.SUCCESS
    },
    [deleteGroupAction.rejected]:(state,action) => {
      state.deleteGroupStatus = STATUS.FAILED
    },

    [updateGroupAction.pending]: (state, action) => {
      state.updateGroupStatus = STATUS.FETCHING
    },
    [updateGroupAction.fulfilled]: (state, action) => {
      SuccessMessage()
      state.updateGroupStatus = STATUS.SUCCESS
      state.singleInstitute.groups = state.singleInstitute.groups.map(grp => grp.id == action.payload.id ? action.payload : grp )
    },
    [updateGroupAction.rejected]: (state, action) => {
        state.updateGroupStatus = STATUS.FAILED
    },
  },
})

export const { resetEditStatusAction } = instituteStaffSlice.actions
export const instituteStaffReducer = instituteStaffSlice.reducer