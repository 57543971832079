import { Button, Card, DatePicker, Drawer, Form, Input, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../Constants'
import { getBatchesRequest } from '../redux/reducers/batches'
import { getStatesAction } from '../redux/reducers/states'
import _ from 'lodash'
import { UpdateUserAction } from '../redux/reducers/alumni'
import moment from 'moment'
import { UploadImageBox } from './UploadImageBox'

export const UpdateProfile = ({currentAlumni, visible, closeDrawer}) => {
    const dispatch = useDispatch()
    const [from] = Form.useForm()
    const {states, batches, user, alumni} = useSelector((state) => ({
        states:state.states,
        batches:state.batches,
        user:state.user.user,
        alumni:state.alumni,
    }))
    
    useEffect(() => {
        if(states.getStatesStatus != STATUS.SUCCESS)
            dispatch(getStatesAction())

        if(states.getBatchesStatus != STATUS.SUCCESS)
            dispatch(getBatchesRequest({id:user.staff.institute.id}))
    }, [])

    const [selectedState, setState] = useState('')
    const [selectedCity, setCity] = useState('')
    const [formKey, setFormKey] = useState(1)
    const [avatar, setAvatar] = useState()

    useEffect(() => {
        if(states.getStatesStatus == STATUS.SUCCESS){
            let state= _.find(states.statesList, st => st.id == currentAlumni.user.addresses[0]?.district?.state.id) 
            setState(state)
            setCity(currentAlumni.user.addresses[0]?.district?.id)
        }
    }, [states.getStatesStatus])


    const selectState = (id) => {
        if(_.find(states.statesList,s => s.id == id))
            setState(_.find(states.statesList,s => s.id == id))
        else
            setState([])
    }

    const updateAlumni = (e) => {
        let data = {...e, dob:e.dob? e.dob.format('YYYY-MM-DD') : null, imageUrl:avatar?.url || null}
        dispatch(UpdateUserAction(data))
    }

    const _selectState = (e) => {
        selectState(e) 
        setFormKey(formKey + 1)
        setCity(null)
    }

    const handleAvatar = (e) => {
        setAvatar(e?.file?.response)
    }

    return(
        <Drawer title='Update Profile' width='50%' onClose={closeDrawer} visible={visible}>
            <Card bordered={false} loading={states.getStatesStatus == STATUS.FETCHING || batches.getBatchesStatus == STATUS.FETCHING}>
                {states.getStatesStatus == STATUS.SUCCESS && batches.getBatchesStatus == STATUS.SUCCESS ?
                    <Form
                        from={from}
                        layout="horizontal"
                        size='large'
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={updateAlumni}
                        key={formKey}
                    >
                        <CustomInput label='Name' required name='name' value={currentAlumni.user.name}/>
                        <Form.Item label="Alumnus Image">
                            <UploadImageBox defaultImg={currentAlumni.user.imageUrl} ratio={'1:1'} 
                                onRemove={() => handleAvatar(null)} getImage={handleAvatar}    
                            />
                        </Form.Item>
                        <CustomInput hidden name='id' value={currentAlumni.user.id}/>
                        <Form.Item label="Gender" initialValue={_.lowerCase(currentAlumni.user.gender)} name='gender'>
                            <Radio.Group defaultValue={_.lowerCase(currentAlumni.user.gender)}>
                                <Radio.Button value="male" >Male</Radio.Button>
                                <Radio.Button value="female">Female</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Date of Birth" name='dob' initialValue={currentAlumni.user.dob && moment(String(currentAlumni.user.dob))}>
                            <DatePicker placeholder='date of birth' defaultValue={currentAlumni.user.dob && moment(String(currentAlumni.user.dob))}/>
                        </Form.Item>
                        <Form.Item label='Primary Contact' required name='contact' initialValue={currentAlumni.user.contact}>
                            <Input placeholder='contact number' required type='number'/>
                        </Form.Item>
                        <Form.Item label='Secondary Contact' name='contactSecondary' initialValue={currentAlumni.user.contactSecondary}>
                            <Input placeholder='contact number' type='number'/>
                        </Form.Item>
                        <CustomInput label='Primary E-mail' required type='email' name='email' value={currentAlumni.user.email}/>
                        <CustomInput label='Secondary E-mail' type='email' name='emailSecondary' value={currentAlumni.user.emailSecondary}/>
                        
                        <Form.Item wrapperCol={ {offset: 4}}>
                            <Button loading={alumni.updateUserStatus === STATUS.FETCHING} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    :
                    null
                }
            </Card>
        </Drawer>
    )
}

const CustomInput = ({label, required, name, placeholder, type, hidden, value}) => {
    return(
        <Form.Item label={label} hidden={hidden} required={required} initialValue={value} name={name}>
            <Input placeholder={placeholder} required={required} type={type || 'text'}/>
        </Form.Item>
    )
}