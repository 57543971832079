import { Button, Card, Checkbox, DatePicker, Form, Input, message, Radio, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { CommonPageHeader } from '../../components/CommonPageHeader'
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { getStatesAction } from '../../redux/reducers/states';
import { STATUS } from '../../Constants';
import _ from 'lodash'
import { addAlumniAction } from '../../redux/reducers/alumni';
import { getBatchesRequest } from '../../redux/reducers/batches';
import Text from 'antd/lib/typography/Text';
import { UploadImageBox } from '../../components/UploadImageBox';

export const AddAlumni = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const {states, batches, user, alumni, addAlumniStatus} = useSelector((state) => ({
        states:state.states,
        batches:state.batches,
        user:state.user.user,
        alumni:state.alumni,
        addAlumniStatus:state.alumni.addAlumniStatus
    }))

    const [permanentAsLocal, setPermananetAsLocal] = useState()
    // const [selectedState, setState] = useState('')
    const [perAddress, setPerAddress] = useState({})
    const [avatar, setAvatar] = useState()

    useEffect(() => {
        dispatch(getBatchesRequest({id:user.staff.institute.id}))
        dispatch(getStatesAction())
    }, [])

    useEffect(() => {
        if(alumni.addAlumniStatus === STATUS.SUCCESS){
            form.resetFields()
            setPerAddress({})
            setPermananetAsLocal(false)
            setAvatar(false)
        }
    }, [alumni.addAlumniStatus, form])

    // const selectState = (id) => {
    //     if(_.find(states.statesList,s => s.id == id))
    //         setState(_.find(states.statesList,s => s.id == id))
    //     else
    //         setState([])
    // }

    const addAlumni = (formData) => {
        let {address, city, pincode, stateId, batchId, name, gender, dob, email, contact, contactSecondary, emailSecondary} = formData
        let local = {address, city, pincode:parseInt(pincode), stateId, type:'correspondence'}
        let permanent = perAddress.address ? {...perAddress, type:'permanent'} : null
        let addresses = _.compact([permanent, local])
        
        let data = {batchId, name, gender, email, imageUrl:avatar?.url, contact, contactSecondary, emailSecondary,  dob:dob?.format('YYYY-MM-DD'), addresses, isVerified:true}
        dispatch(addAlumniAction(data))
    }

    const handlePermanentAddress = () => {
        let data
        let formData = form.getFieldsValue()
        if(!permanentAsLocal)
            data = {address:formData.address, stateId:formData.stateId, city:formData.city, pincode:formData.pincode}
        else
            data = {}

        setPerAddress(data)
        setPermananetAsLocal(!permanentAsLocal)
    }

    const handleChangeValue = (e, type) => {
        let data
        
        if(type)
            data = Object.assign({}, perAddress, {[type]:e})
        else
            data = Object.assign({}, perAddress, {[e.target.id]:e.target.value})
        
        setPerAddress(data)
    }

    const handleAvatar = (e) => {
        setAvatar(e?.file?.response)
    }

    return(
        <div>
            <CommonPageHeader
                title='Add Alumni'
            />
            <br/>
            <Card loading={states.getStatesStatus == STATUS.FETCHING || batches.getBatchesStatus == STATUS.FETCHING}>
                {states.getStatesStatus == STATUS.SUCCESS  && batches.getBatchesStatus == STATUS.SUCCESS ?
                    <div>
                        <Form
                            form={form}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            size='large'
                            onFinish={addAlumni}
                        >
                            <Form.Item label="Batch" rules={[{ required: true, message: 'Please select field.' }]} name='batchId'>
                                <Select showSearch autoComplete='invalid' placeholder='select batch' allowClear
                                    // onChange={selectState}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {batches.batches.length &&  batches.batches.map(state => 
                                        <Select.Option value={state.id} key={state.id}>{state.name}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <CustomInput hidden name='instituteId' value={user.staff.institute.id}/>
                            <Form.Item label="Alumus Image">
                                <UploadImageBox defaultImg={avatar?.url} ratio={'1:1'} onRemove={() => handleAvatar(null)} getImage={handleAvatar}/>
                            </Form.Item>
                            <CustomInput label="Name" rules={[{ required: true, message: 'Please fill in the field.' }]} name='name' placeholder='name'/>
                            <Form.Item label="Gender" rules={[{ required: true, message: 'Please select gender.' }]} name='gender'>
                                <Radio.Group>
                                    <Radio.Button value="male" >Male</Radio.Button>
                                    <Radio.Button value="female">Female</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Date of Birth" name='dob'>
                                <DatePicker name placeholder='date of birth'/>
                            </Form.Item>
                            <Form.Item label='Primary Contact' 
                                rules={[{ required: true, message: 'Please fill in the field.' }, {pattern:'^[7-9][0-9]{9}$', message:'Enter valid contact number'}]} name='contact'
                            >
                                <Input placeholder='contact' type='number'/>
                            </Form.Item>
                            <Form.Item label='Secondary Contact' 
                                rules={[{pattern:'^[7-9][0-9]{9}$', message:'Enter valid contact number'}]} name='contactSecondary'
                            >
                                <Input placeholder='contact' type='number'/>
                            </Form.Item>
                            <CustomInput label="Primary E-mail" rules={[{ required: true, message: 'Please fill in the field.' }]} type='email' name='email' placeholder='email'/>
                            <CustomInput label="Secondary E-mail" type='emailSecondary' name='emailSecondary' placeholder='email'/>

                            <Form.Item label={<Text style={{fontSize:18, fontWeight:'bold'}}>Local Address</Text>}>
                            </Form.Item>
                            <Form.Item label="Address" name='address'>
                                <Input.TextArea placeholder='address'/>
                            </Form.Item>
                            <Form.Item label="State" name='stateId'>
                                <Select showSearch autoComplete='invalid' allowClear placeholder='select state'
                                    // onChange={selectState}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states?.statesList ? 
                                        _.orderBy(states.statesList, 'name',  'asc').map(state => 
                                            <Select.Option value={state.id} key={state.id}>{state.name}</Select.Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                            </Form.Item>
                            <CustomInput label="City" name='city' placeholder='city'/>
                            {/* <Form.Item label="City" name='cityId'>
                                <Select showSearch autoComplete='invalid' allowClear placeholder='select city'
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {selectedState?.cities?.length ? selectedState.cities.map(city => 
                                        <Select.Option value={city.id} key={city.id}>{city.name}</Select.Option>
                                    ) : null}
                                </Select>
                            </Form.Item> */}
                            <CustomInput label="Pin Code" required name='pincode' type='number' placeholder='pincode'/>


                            <Form.Item label={<Text style={{fontSize:18, fontWeight:'bold'}}>Permanent Address</Text>}>
                                <Checkbox checked={permanentAsLocal} onChange={handlePermanentAddress}>Same as Correspondence address</Checkbox>
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input.TextArea placeholder='address' value={perAddress.address} id='address' onChange={handleChangeValue}/>
                            </Form.Item>
                            <Form.Item label="State">
                                <Select showSearch autoComplete='invalid' value={perAddress.stateId} allowClear placeholder='select state'
                                    // onChange={selectState}
                                    onChange={(e) => handleChangeValue(e, 'stateId')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states?.statesList ? 
                                        _.orderBy(states.statesList, 'name',  'asc').map(state => 
                                            <Select.Option value={state.id} key={state.id}>{state.name}</Select.Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="City">
                                <Input placeholder='city' value={perAddress.city} id='city' onChange={handleChangeValue}/>
                            </Form.Item>
                            <Form.Item label="Pincode">
                                <Input placeholder='pincode' value={perAddress.pincode} type='number' id='pincode' onChange={handleChangeValue}/>
                            </Form.Item>
                            {/* <CustomInput label="City" id='city' value={perAddress.city} onChange={handleChangeValue} placeholder='city'/>
                            <CustomInput label="Pin Code" required id='pincode' value={perAddress.pincode} onChange={handleChangeValue} type='number' placeholder='pincode'/> */}

                            <Form.Item wrapperCol={ {offset: 6}}>
                                <Button loading={addAlumniStatus === STATUS.FETCHING} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    :
                    null
                }
                <br/>
            </Card>
        </div>
    )
}

const CustomInput = ({label, required, name, placeholder, type, hidden, value, rules, onChange, id}) => {
    return(
        <Form.Item label={label} hidden={hidden} rules={rules} initialValue={value} name={name}>
            <Input placeholder={placeholder} id={id} type={type || 'text'} onChange={onChange} />
        </Form.Item>
    )
}

const ImgUpload = () => {
    const [loadingImg, setLoadingImg] = useState(false)
    const [imageUrl, setImgUrl] = useState('')

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoadingImg(true)
        }
        if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, url =>{
            setLoadingImg(false)
            setImgUrl(url)
        })
    }}

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadButton = (
      <div>
        {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return(
        <Upload
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
            action="app/file"
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
    )
}