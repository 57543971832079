
export const ROUTES = {
    INSTITUTE_ADD: "/institute/add",
    INSTITUE_LIST: "/institute/list",
    MANAGE_BATCHES: { key:"/manage-batches/:id?", rootPath: "/manage-batches" },
    ADD_ALUMNI: "/add-alumni",
    SEARCH_ALUMNI: "/alumni/search",
    STUDENT_PROFILE: "/alumni/profile/:id",
    ADD_GROUP:{key:"/manage-groups/:id?", rootPath:"/manage-groups"},
    ALUMNIS_LIST:{key:'/alumnis/:pageNumber?', rootPath:'/alumnis'},
    MANAGE_POSTS:'/manage-posts',
    SEND_NOTIFICATIONS:'/notifications',
    MANAGE_NOTIFICATIONS:'/manage-notifications',
    MY_PROFILE:'/user-profile'
}