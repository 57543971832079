import { Button, Form, Input } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import Title from 'antd/lib/typography/Title'
import React, { useReducer } from 'react'
import { useAppContext } from '../../App/Context'
import { ErrorMessage } from '../../Constants/CommonAlerts'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'
import { FormReducer } from '../../utils/FormReducer'
import "./Login.css"

export const LoginRoute = props => {
    const {loginSuccess} = useAppContext()

    const [loginData, dispatchPropertyChange] = useReducer(FormReducer,{})

    const _changeUsername = value => {
        dispatchPropertyChange({type:"username", value: value.target.value})
    }

    const _changePassword = value => {
        dispatchPropertyChange({type:"password", value : value.target.value})
    }


    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const {request : loginRequest, loading, reset} = useApiRequest(URIS.LOGIN, {
        onCompleted: data => {
          loginSuccess(data)

        },
        onError : (data, error) => {
            ErrorMessage(error?.message)
        }
    } ) 
    
    const login = () => {
       loginData && loginData.username && loginData.password &&  loginRequest({data:loginData})
    }

    return (
        <div className="Container">
            <div className="App" className="left">
                <Title style={{color:'white'}} >Welcome Alumnus</Title>
            </div>

            <div className="content" style={{flex:2}}>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}                    
                     onFinish={login}
                    // onFinishFailed={onFinishFailed}
                    >
                    <Form.Item
                        label="Username"
                        name="username"
                        onChange={_changeUsername}

                        value={loginData.username}
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        value={loginData.Password}
                        onChange={_changePassword}
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button  htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <br/>
            </div>
        </div>
    )
}