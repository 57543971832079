import './App.less';
import React from "react";
import { AppProvider } from "./AppProvider";
import AppRouter from '../routes';
import { Provider } from 'react-redux';
import { setupStore } from '../redux';

const store = setupStore()

export const App = (props) => {
  return (
    <Provider store={store}>
    <AppProvider>
      <AppRouter/>
    </AppProvider>
    </Provider>

  );
};
