import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { STATUS } from "../../../Constants"
import { SuccessMessage } from "../../../Constants/CommonAlerts"
import { URIS } from "../../../services/api"
import { apis } from "../../../services/api/apis"

export const getAllPostsAction = createAsyncThunk(
    'get-all/posts',
    async (payload, thunkAPI) => {
      const response = await apis.getAllPostsApi(payload)
      const { ok, problem, data } = response
      if (ok) {
        return data
      } else {
       return thunkAPI.rejectWithValue(problem)
      }
    }
  )

const initialState = {}

const userSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    
  },
  extraReducers:{
      [getAllPostsAction.pending]:(state, action) => {
        state.getAllPostsStatus = STATUS.FETCHING
      },
      [getAllPostsAction.fulfilled]: (state, action) => {
          state.getAllPostsStatus = STATUS.SUCCESS
          state.allPosts = action.payload
      },
      [getAllPostsAction.rejected] : (state, action) => {
        state.getAllPostsStatus = STATUS.FAILED
      },
  },
})

export const postReducer = userSlice.reducer