import moment from "moment";
import {
  Card,
  Form,
  Modal,
  Button,
  Input,
  DatePicker,
  Switch,
  Select,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import {
  addExperienceAction,
  editExperienceAction,
  resetExperienceStatusAction,
  deleteAlumniExperienceAction,
} from "../../redux/reducers/alumni";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { STATUS } from "../../Constants";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

const tailLayout = {
  wrapperCol: { offset:4, span: 24 },
};

const EmploymentTypeData = [
  {id: 1, type: "Full-time"}, 
  {id: 2, type: "Part-time"},
  {id: 3, type: "Self-employeed"},
  {id: 4, type: "Freelance"},
  {id: 5, type: "Internship"},
  {id: 6, type: "Trainee"}
]

export const AddAlumniExperienceModal = (props) => {
  const dispatch = useDispatch();

  let [formKey, setFromKey] = useState(1)

  const {editExperienceStatus, deleteAlumniExperienceStatus, addExperienceStatus} = useSelector(state => ({
    editExperienceStatus:state.alumni.editExperienceStatus,
    deleteAlumniExperienceStatus:state.alumni.deleteAlumniExperienceStatus,
    addExperienceStatus:state.alumni.addExperienceStatus

  }))

  const [stateData, stateChange] = useState({
    memberId: props.alumni.currentAlumni.id,
    location: '',
    title: '',
    company: '',
    employmentType: "",
    description: '',
    startDate: '',
    endDate: '',
    current: false
  });

  const curretStateData = () => {
    stateChange({
      memberId: props.alumni.currentAlumni.id,
      location: props.preSelected.location || "",
      title: props.preSelected.title || "",
      company: props.preSelected.company || "",
      employmentType: props.preSelected.employmentType ? _.get(_.find(EmploymentTypeData, e => e.type == props.preSelected.employmentType), 'id', '') : "",
      description: props.preSelected.description || "",
      startDate: props.preSelected.startDate ? moment(props.preSelected.startDate) : "",
      endDate: props.preSelected.endDate ? moment(props.preSelected.endDate) : "",
      current: props.preSelected.current || false,
    });
  };

  useEffect(() => {
    if (props.preSelected) {
      curretStateData();
      setFromKey(++formKey)
    }
  }, [props.preSelected]);

  // useEffect(() => {
  //   if (props.alumni.addExperienceStatus === "SUCCESS" || props.alumni.deleteAlumniExperienceStatus === 'SUCCESS') {
  //     stateChange({
  //       memberId: props.alumni.currentAlumni.id,
  //       location: '',
  //       title: '',
  //       company: '',
  //       employmentType: '',  
  //       description: '',
  //       startDate: '',
  //       endDate: '',
  //       current: false
  //     });
  //     dispatch(resetExperienceStatusAction());
  //     props.closeModal();
  //   }
  // }, [props.alumni.addExperienceStatus, props.alumni.deleteAlumniExperienceStatus]);


  const onFinish = () => {
    let data= {
      location:  stateData.location,
      title:  stateData.title,
      company:  stateData.company,
      employmentType: _.get(_.find(EmploymentTypeData, e => e.id == stateData.employmentType), 'type', ''),
      description: stateData.description,
      startDate: stateData.startDate ? moment(stateData.startDate).format('YYYY/MM/DD') : null,
      endDate:  stateData.current || !stateData.endDate || !stateData.startDate ? null : moment(stateData.endDate).format('YYYY/MM/DD'),
      current: stateData.current
    }

    if (props.preSelected) {
      data = {id: props.preSelected.id, ...data};
      dispatch(editExperienceAction(data));
    } else {
      data = {memberId: props.alumni.currentAlumni.id, ...data};
      dispatch(addExperienceAction(data));
    } 
  };

  let selectFormData = (value, type) => {
    if(type == 'current' && value == true){
      stateChange({ ...stateData, endDate: '', [type]: value });
    }else{
      stateChange({ ...stateData, [type]: value });
    }
  };

  const deleteAlumniExperience = (id) => {
    dispatch(deleteAlumniExperienceAction({id}));
  }
  
  const rules = [{required:true, message:'Please fill in the field.'}]
  return (
    <Modal
      visible={props.experienceModal}
      footer={null}
      width="1000px"
      onOk={() => console.log("")}
      onCancel={() => props.closeModal()}
      key={formKey}
    >
      <div style={{ padding: "20px" }}>
        <Card title={
          <div>
            <b>{props.preSelected ? "Update Experience" : "Add Experience"}</b>
          </div>} 
        bordered={false} style={{ width: "100%" }}>
          <Form {...formItemLayout} layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item name='title' label="Title"
              rules={rules} initialValue={stateData.title}
            >
              <Input
                placeholder="Input Title"
                onChange={(e) => selectFormData(e.target.value, "title")}
              />
            </Form.Item>
            <Form.Item name='company' label="Company"
              rules={rules} initialValue={stateData.company}
            >
              <Input
                placeholder="Input Company"
                onChange={(e) => selectFormData(e.target.value, "company")}
              />
            </Form.Item>
            <Form.Item name='type' label="Employment Type" 
              rules={rules} initialValue={stateData.employmentType}
            >
              <Select placeholder='Select Employment Type' onChange={(e) => selectFormData(e, "employmentType")}>
                  
                  {EmploymentTypeData.map((s,i) => {
                    return(
                      <Select.Option value={s.id} key={i}>{s.type}</Select.Option>
                    )}
                  )}
              </Select>
            </Form.Item>
            <Form.Item name='description' label="Description" initialValue={stateData.description}>
              <Input
                placeholder="Input Description"
                onChange={(e) => selectFormData(e.target.value, "description")}
              />
            </Form.Item>
            <Form.Item name='location' label="Location" initialValue={stateData.location}>
              <Input
                placeholder="Input Location"
                onChange={(e) => selectFormData(e.target.value, "location")}
              />
            </Form.Item>
            <Form.Item  label="Start Date" style={{ marginBottom: 0 }} >
              <Form.Item name='startDate' style={{ display: "inline-block", width: "43%" }} initialValue={stateData.startDate}>
                <DatePicker onChange={(e) => selectFormData(e, "startDate")}/>
              </Form.Item>
              <span style={{ display: "inline-block", width: "80px", lineHeight: "32px", textAlign: "center" }}>
                End Date:
              </span>
              <Form.Item name='endDate' style={{ display: "inline-block", width: "30%" }} initialValue={stateData.endDate}>
                <DatePicker disabled={stateData.current} onChange={(e) => selectFormData(e, "endDate")}/>
              </Form.Item>
            </Form.Item>
            <Form.Item label="Currently Working">
              <Switch checked={stateData.current} defaultChecked={false} onChange={(e) => selectFormData(e, "current")}/>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" loading={addExperienceStatus === STATUS.FETCHING || editExperienceStatus === STATUS.FETCHING} htmlType='submit'>
                  {props.preSelected ? "Update" : "Add"}
                </Button>&nbsp;&nbsp;
                {props.preSelected ? 
                  <Button type='danger' loading={deleteAlumniExperienceStatus === STATUS.FETCHING} onClick={() => deleteAlumniExperience(props.preSelected.id)}>Delete</Button>
                  : null
                }
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Modal>
  );
};
