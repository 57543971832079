import { Modal, Form, Input, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../Constants';
import { addAchievementAction, deleteAchievementAction, updateAchievementAction } from '../../redux/reducers/alumni';

const formItemLayout = {
    labelCol: {
      xs: { span: 22 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 },
    },
  };

export const AddAchievementModal = ({visible, closeModal, alumni, currentAchievement}) => {
    const dispatch = useDispatch()
    const update = currentAchievement ? true : false

    const {addAchievementStatus, updateAchievementStatus, deleteAchievementStatus} = useSelector(state => ({
        addAchievementStatus:state.alumni.addAchievementStatus,
        updateAchievementStatus:state.alumni.updateAchievementStatus,
        deleteAchievementStatus:state.alumni.deleteAchievementStatus
    }))
    
    const [achievementData, changeData] = useState({})

    useEffect(() => {
        if(currentAchievement){
            let {title, description} = currentAchievement
            changeData({title, description})
        }
    }, [currentAchievement])

    const handleSubmit = () => {
        let data ={memberId:alumni.id, ...achievementData}

        if(update){
            data = {...data, id:currentAchievement.id}
            dispatch(updateAchievementAction(data))
        }else{
            dispatch(addAchievementAction(data))
        }
    }

    const handleChangeData = (e) => {
        changeData(d => ({...d, [e.target.id]:e.target.value}))
    }

    const handleDelete = () => {
        dispatch(deleteAchievementAction({id:currentAchievement.id}))
    }

    return(
        <Modal title={update ? 'Update Achievement' : 'Add Achievement'} onCancel={closeModal} visible={visible} width='50%'
            footer={[
                <Button onClick={closeModal}>Cancel</Button>,
                update ? <Button onClick={handleDelete} danger loading={deleteAchievementStatus === STATUS.FETCHING}>Delete</Button> : null,
                <Button type='primary' htmlType='submit' disabled={!(achievementData.title && achievementData.description)}
                    loading={addAchievementStatus === STATUS.FETCHING || updateAchievementStatus === STATUS.FETCHING}
                    onClick={handleSubmit}
                >
                    {update ? 'Update' : 'Add'}
                </Button>,
            ]}
        >
            <div>
                <Form {...formItemLayout} layout="horizontal">
                    <Form.Item required label='Title'>
                        <Input placeholder='title' id='title' value={achievementData.title} onChange={handleChangeData} />
                    </Form.Item>
                    <Form.Item required label='Description'>
                        <Input.TextArea placeholder='description' value={achievementData.description} id='description' onChange={handleChangeData} rows={6} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
} 