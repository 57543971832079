import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { STATUS } from "../../../Constants"
import { SuccessMessage } from "../../../Constants/CommonAlerts"
import { URIS } from "../../../services/api"
import { apis } from "../../../services/api/apis"

export const sendNotificationAction = createAsyncThunk(
    'notification/post',
    async (payload, thunkAPI) => {
      const response = await apis.sendNotificationApi(payload)
      const { ok, problem, data } = response
      if (ok) {
        return data
      } else {
       return thunkAPI.rejectWithValue(problem)
      }
    }
)

export const getNotificationsAction = createAsyncThunk(
  'notification/get',
  async (payload, thunkAPI) => {
    const response = await apis.getNotificationsApi(payload)
    const { ok, problem, data } = response
    if (ok) {
      return data
    } else {
     return thunkAPI.rejectWithValue(problem)
    }
  }
)

const initialState = {}

const userSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    
  },
  extraReducers:{
      [sendNotificationAction.pending]:(state) => {
        state.sendNotificationStatus = STATUS.FETCHING
      },
      [sendNotificationAction.fulfilled]: (state, action) => {
          SuccessMessage('Sent Successsfully')
          state.sendNotificationStatus = STATUS.SUCCESS
      },
      [sendNotificationAction.rejected] : (state) => {
        state.sendNotificationStatus = STATUS.FAILED
      },

      [getNotificationsAction.pending]:(state) => {
        state.getNotificationsStatus = STATUS.FETCHING
      },
      [getNotificationsAction.fulfilled]: (state, action) => {
          state.getNotificationsStatus = STATUS.SUCCESS
          state.allNotifications = action.payload
      },
      [getNotificationsAction.rejected] : (state) => {
        state.getNotificationsStatus = STATUS.FAILED
      },
  },
})

export const notificationReducer = userSlice.reducer